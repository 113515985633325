.space10 {height: 10px;}
.space20 {height: 20px;}
.space30 {height: 30px;}
.space40 {height: 40px;}
.space50 {height: 50px;}
.space60 {height: 60px;}


*{margin: 0;padding: 0;image-rendering: -o-crisp-edges;image-rendering: -webkit-optimize-contrast;-ms-interpolation-mode: nearest-neighbor}
body,html,main{-webkit-font-smoothing: antialiased}
a,a:focus,button,button:focus{outline: 0 !important;box-shadow: none;}
a{display: inline-block;text-decoration: none !important;}
.bg-layer{opacity: 1;background-size: cover;background-position: center center;}
.bg-layer{position: absolute;top: 0;left: 0;}
.bg-layer,iframe,video,source{height: 100%;width: 100%}
img,picture,svg{max-width: 100%;}
.full-div{display: inline-block;width: 100%;}
.half-div{display: inline-block;width: 50%;float: left;}
.txt-right,.text-right{text-align: right;}
.txt-center,.txt-centr,.text-center{text-align: center;}
.txt-left,.text-left{text-align: left;}
section{padding-top: 100px;padding-bottom: 100px;position: relative;
  @media(max-width:991px){padding-top: 80px;padding-bottom: 80px;}
  @media(max-width:767px){padding-top: 50px;padding-bottom: 50px;}
}
.flex-div{display: flex;justify-content: space-between;width:100%;align-items: center;}
.flex-div-md{display: flex;justify-content: space-between;width:100%;
  @media(max-width:991px){display: inline-block;}
}
.flex-div-sm{display: flex;justify-content: space-between;width:100%;
  @media(max-width:767px){display: inline-block;}
}
.flex-div-xs{display: flex;justify-content: space-between;width:100%;
  @media(max-width:575px){display: inline-block;}
}
.d-flex-sm{
  @media(min-width:768px){display: flex;}
}
body,html{min-height: 100%;height: auto;}
img{max-width: 100%;}
button,div,img,h1,h2,h3,h4,h5,h6,p,a,li,span{@include trans();}
ul{padding-left: 0;}
.pd-r-0{padding-right: 0;}
.pd-l-0{padding-left: 0;}
body{overflow-x: hidden !important;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
input:focus,button:focus{box-shadow: none !important;}
.mobile-view{display: none;}
.table-mobile-view{display: none;}
@media(max-width:991px){
  .table-web-view{display: none;}
  .table-mobile-view{display: inline-block;}
}
.text-right-web{text-align: right;}
@media(max-width:767px){
    .web-view{display: none;}
    .mobile-view{display: block;}
    .mmt-3{margin-top: 20px;}
    .m-j-c{justify-content: space-between;}
    .mb-text-left{text-align: left;}   
    .text-right-web{text-align: left;}
}
.mobile-view-md{display: none;}
@media(max-width:991px){
    .web-view-md{display: none;}
    .mobile-view-md{display: block;}
}

@media (min-width: 1170px) {.container {max-width: 1170px;}}
// @media(min-width:1401px){.container{max-width: 1320px;}}
@media (max-width: 1170px) {.container {max-width: 100%;}}

@media (max-width: 767px) {
  .mobile-view-margin{margin-top:20px;}
}
/**** divider ***/
.spacer-5{width: 100%;height: 5px;display: block;clear: both;}
.spacer-10{width: 100%;height: 10px;display: block;clear: both;}
.spacer-20{width: 100%;height: 20px;display: block;clear: both;}
.spacer-30{width: 100%;height: 30px;display: block;clear: both;}
.spacer-40{width: 100%;height: 40px;display: block;clear: both;}
.spacer-50{width: 100%;height: 50px;display: block;clear: both;}
.spacer-60{width: 100%;height: 60px;display: block;clear: both;}
.hidden{display: none;}
.web-view{
  @media(max-width:991px){display: none !important;}
}
.tablet-view{
  @media(min-width:992px){display: none !important;}
}

.flex-contr{display: flex;justify-content: space-between;width:100%;
  @media (max-width:1200px){
    &.lg{display: inline-block;
      .w-5,.w-7{width: calc(100% - 30px);margin-left: 15px;margin-right: 15px;}
    }
  }
  @media (max-width:767px){display: inline-block;width:100%;}
  .w-7{width: calc(58.333333% - 15px);margin-left: 2.5px;margin-right: 2.5px;
    @media (max-width:1200px){
      width: calc(50% - 30px);
      margin-left: 15px;margin-right: 15px;
    }
    @media (max-width:767px){
      width: calc(100% - 30px);
    }
  }
  .w-5{width: calc(41.666667% - 15px);margin-left: 15px;margin-right: 15px;
    @media (max-width:1200px){
      width: calc(50% - 30px);
    }
    @media (max-width:767px){
      width: calc(100% - 30px);
    }
  }
  .w-8{width: calc(66.666667% - 30px);margin-left: 15px;margin-right: 15px;
    @media (max-width:1200px){
      width: calc(100% - 30px);
    }
  }
  .w-4{width: calc(33.33333% - 30px);margin-left: 15px;margin-right: 15px;
    @media (max-width:1200px){
      width: calc(100% - 30px);
    }
  }
}
// ::placeholder{color: $lightgrey;}
// :-ms-input-placeholder {color: $lightgrey;}
// ::-ms-input-placeholder {color: $lightgrey;}
// input[type="checkbox"]{accent-color: $color;}
body{  font-family: "Agdasima", sans-serif;font-weight: 400;font-size: 16px;}
ul{list-style: none;}
.img-sp{position: relative;top: -2px;}
.zindex{z-index: 5;position: relative;}
.btn-list{display: flex;margin:0;
  &.justify-center{justify-content: center;
    @media(max-width:767px){
      flex-direction: column;
      a,button{width: 100%;text-align: center;padding-left: 0;padding-right: 0;}
    }
  }
  li{display: inline-block;margin:0 7.5px 15px;}
}
