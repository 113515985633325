@mixin shadow {
  text-shadow: 0 0px 10px rgba(255, 255, 255, 0.5);
}

@mixin boxshadow {
  box-shadow: inset 0px 0px 30px rgba(255, 255, 255, 0.15);
}

@mixin gradient {
  background: linear-gradient(177.16deg, #3EAC91 9.11%, #2F7566 86.55%);
}

@mixin trans {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
