
/* Button */
.reg-btn {
  display: inline-block;
  border: none !important;
  padding: 5px 25px;
  text-align: center;
  @include trans();
  font-size: 20px;
  color: $white;
  border-radius: 10px;
  cursor: pointer;
  @include gradient();
  &:hover {
    color: $white;
    box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.4);
  }

  &:focus {
    border: none !important;
    box-shadow: none !important;
  }

  &.trans {
    background-color: transparent !important;
    background-image: none !important;
    @include boxshadow();

    &:hover {
      background-color: $color !important;
    }
  }

  &:disabled {
    background-color: transparent;
    background-image: none;
    @include boxshadow();

    &:hover {
      background-color: $color;
    }
  }

  &.small {
    padding: 6px 15px;
  }

  &.brdr-rad {
    border-radius: 10px;
  }

  &.brdr-rod {
    border-radius: 15px;
  }
}

/* Button */

.shadowww {
  display: inline-block;
  width: 100%;
  padding: 15px 15px;
  border-radius: 10px;
  box-shadow: inset 0px 0px 30px rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 10px;
}

.shadow-box {
  display: inline-block;
  width: 100%;
  border-radius: 15px;
  position: relative;
  max-width: 600px;
  padding: 50px 40px 60px;
  box-shadow: inset 0px 0px 30px rgba(255, 255, 255, 0.15);
  margin-bottom: 40px;

  @media (max-width: 575px) {
    padding: 40px 20px 40px;
  }

  &:before {
    position: absolute;
    content: "";
    left: 5px;
    right: -5px;
    bottom: 15px;
    top: -15px;
    box-shadow: inset 0px 0px 30px rgba(255, 255, 255, 0.15);
    border-radius: 15px;
    z-index: -1;

    @media (max-width: 575px) {
      display: none;
    }
  }
}

/*general*/
html {
  scroll-behavior: auto;
}

body {
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  background-color: #0b0b11;
  background-image: url(../images/bg.png);
  background-size: 100% 100%;
}

#routerhang {
  transform: unset !important;
  transform-origin: unset !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.br {
  display: block;
  white-space: pre-wrap;
}

strong {
  font-weight: bold;
}

section {
  padding: 40px 0;

  &.no-top {
    margin-top: 80px;
    padding-top: 0 !important;
  }

  &.no-bottom {
    padding-bottom: 0 !important;
  }

  .small-border {
    display: block;
    width: 50px;
    height: 2px;
    background: $color;
    border-left: none;
    border-left-color: currentcolor;
    border-right: none;
    border-right-color: currentcolor;
    margin: 18px auto 30px;
  }

  &.bg-gray {
    background: rgb(247, 244, 253);
  }
}

.wraper {
  padding: 0;
  display: block;
  overflow: hidden;
  width: 100%;

  .wraperitem {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.m-2-hor {
  padding: 0 7%;
  max-width: 1500px;
  margin: 0 auto !important;
}

.m-10-hor {
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  max-width: 1500px;
  margin: 0 auto;
}

.align-items-center {
  align-items: center !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.white {
  color: $black;
  background: $white;
}

.color {
  color: $color;
}

.btn-custom {
  position: relative;
  overflow: hidden;
  font-size: 11pt;
  color: $white;
  background: none;
  border: 2px solid $color;
  border-radius: 0;
  padding: 10px 44px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  z-index: 0;

  span {
    color: $white;
    position: relative;
    z-index: 1;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-image: linear-gradient(to left, $color, $color);
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
    transition: all 0.3s ease;
  }

  &:hover {
    .shine {
      -webkit-animation: sheen 0.6s alternate;
      animation: sheen 0.6s alternate;
    }
  }
}

.shine {
  content: "";
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: -50px;
  background: -webkit-linear-gradient(
    top,
    transparent,
    rgba(255, 255, 255, 0.2) 5%,
    transparent
  );
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(255, 255, 255, 0.2) 5%,
    transparent
  );
  -webkit-transform: rotateZ(60deg) translate(-1em, 5.5em);
  transform: rotateZ(60deg) translate(-1em, 5.5em);
}

@-webkit-keyframes sheen {
  0% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, 6em);
    transform: rotateZ(60deg) translate(0, 6em);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
}

@keyframes sheen {
  0% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, 6em);
    transform: rotateZ(60deg) translate(0, 6em);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
}

.text-gradient {
  background-clip: text;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  background: linear-gradient(to bottom, $color, $color);
}

.spacer-10 {
  width: 100%;
  height: 10px;
  display: block;
  clear: both;
}

.de-flex {
  display: flex;
  justify-content: space-between;
}

.de-flex > .de-flex-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

/**** divider ***/
.spacer-single {
  width: 100%;
  height: 30px;
  display: block;
  clear: both;
}

.spacer-double {
  width: 100%;
  height: 60px;
  display: block;
  clear: both;
}

.spacer-half {
  width: 100%;
  height: 15px;
  display: block;
  clear: both;
}

.spacer-10 {
  width: 100%;
  height: 10px;
  display: block;
  clear: both;
}

.spacer-20 {
  width: 100%;
  height: 20px;
  display: block;
  clear: both;
}

.spacer-30 {
  width: 100%;
  height: 30px;
  display: block;
  clear: both;
}

.spacer-40 {
  width: 100%;
  height: 40px;
  display: block;
  clear: both;
}

.spacer-50 {
  width: 100%;
  height: 50px;
  display: block;
  clear: both;
}

.spacer-60 {
  width: 100%;
  height: 60px;
  display: block;
  clear: both;
}

hr {
  display: block;
  clear: both;
  border-top: solid 1px #ddd;
  margin: 40px 0 40px 0;
}

/**** heading ***/
h1,
h2,
h4,
h3,
h5,
h6,
.h1_big,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  font-weight: 700;
  color: $white;
}

h1,
.h1 {
  font-size: 50px;
  margin-bottom: 20px;
  line-height: 1.2em;
  letter-spacing: -1px;
}

@media (max-width: 767px) {
  h1,
  .h1 {
    font-size: 40px;
  }
}

@media (max-width: 575px) {
  h1,
  .h1 {
    font-size: 35px;
  }
}

h1 .label {
  display: inline-block;
  font-size: 36px;
  padding: 0 6px;
  margin-left: 10px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

h1.s1 {
  letter-spacing: 30px;
  font-size: 26px;
}

h1 .small-border {
  margin-top: 30px;
}

h1.big,
.h1_big {
  font-size: 64px;
  margin: 0;
  line-height: 70px;
}

h1.very-big {
  font-size: 120px;
  letter-spacing: -5px;
}

h1.ultra-big {
  font-size: 140px;
  line-height: 120px;
  letter-spacing: -6px;
  font-weight: 700;
  margin-bottom: 0;
}

h1.ultra-big span {
  display: inline-block;
}

h1.ultra-big span.underline span {
  display: block;
  border-bottom: solid 12px #fff;
  position: relative;
  margin-top: -5px;
}

h1.very-big-2 {
  font-size: 90px;
  letter-spacing: 25px;
  text-transform: uppercase;
  font-weight: bold;
}

.h2_title {
  font-size: 28px;
  display: block;
  margin-top: 0;
  line-height: 1.2em;
}

h2 .small-border {
  margin-left: 0;
  margin-bottom: 15px;
  width: 40px;
}

h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 36px;
}

h2.style-2 {
  font-size: 30px;
  margin-bottom: 20px;
}

h2.big {
  font-size: 48px;
  line-height: 1.3em;
  margin-bottom: 0;
}

h2 .uptitle {
  display: block;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  font-weight: 500;
}

h2.s1,
.h2_s1 {
  font-size: 24px;
}

h2.deco-text span {
  display: block;
  line-height: 0.85em;
  font-weight: lighter;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

h2.deco-text .md {
  font-size: 80px;
}

h2.deco-text .lg {
  font-size: 120px;
}

h2.deco-text .xl {
  font-size: 150px;
}

h4.teaser {
  font-weight: 300;
  font-size: 22px;
}

.subtitle.s2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.subtitle.s2 span {
  margin-right: 20px;
  padding-left: 20px;
}

.subtitle.s2 i {
  margin-right: 10px;
}

.subtitle.s2 span:first-child {
  padding-left: 0;
}

h4.s1 {
  letter-spacing: 10px;
  font-weight: 400;
  font-size: 16px;
}

h4.s2 {
  font-weight: 200;
  line-height: 1.8em;
}

h4.s3 {
  font-size: 60px;
  font-weight: lighter;
}

h4.s3 {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 15px;
}

.call-to-action h4 {
  text-transform: none;
  font-size: 20px;
}

h1.slogan_big {
  font-weight: 400;
  font-size: 64px;
  line-height: 64px;
  letter-spacing: -2px;
  padding: 0;
  margin: 0px 0 30px 0;
}

h1.title {
  font-size: 64px;
  letter-spacing: 10px;
}

h1.title strong {
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
}

h1.hs1 {
  font-size: 96px;
  display: inline-block;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

h1.hs2 {
  font-size: 72px;
  display: inline-block;
  font-weight: lighter;
}

h2.subtitle {
  margin-top: 0;
}

h2.name {
  color: #fff;
  font-size: 84px;
  line-height: 50px;
}

h2.name span {
  display: block;
  font-size: 32px;
}

h2.name-s1 {
  color: #fff;
  font-size: 84px;
  font-weight: 700;
  line-height: 50px;
}

h2.name-s1 span {
  display: block;
  font-size: 32px;
}

h2.hw {
  display: block;
  font-size: 48px;
  text-transform: none;
  font-weight: lighter;
}

h2.deco {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}

h2.deco span {
  display: inline-block;
  position: relative;
}

h2.deco span:before,
h2.deco span:after {
  content: "";
  position: absolute;
  border-top: 1px solid #bbb;
  top: 10px;
  width: 100px;
}

h2.deco span:before {
  right: 100%;
  margin-right: 15px;
}

h2.deco span:after {
  left: 100%;
  margin-left: 15px;
}

h2.hs1 {
  font-size: 40px;
}

h2.hs1 i {
  font-size: 48px;
  position: relative;
  top: 10px;
  color: #ff0042;
  margin: 0 10px 0 10px;
}

h2.hs1 span {
  font-size: 48px;
  position: relative;
  top: 10px;
  margin: 0 15px 0 10px;
  font-weight: normal;
}

h2 .de_light .text-light h2 {
  color: #fff;
}

.text-light h2.deco span:before,
.text-light h2.deco span:after {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

h2.s2 {
  font-weight: 400;
}

h2.s3 {
  font-size: 36px;
  margin-bottom: 20px;
}

h4.title {
  border-bottom: solid 1px #ddd;
  margin-bottom: 20px;
}

h4.style-2 {
  font-size: 18px;
}

h4.title {
  border-bottom: solid 1px #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

h5.s2 {
  letter-spacing: 1px;
  font-size: 14px;
}

h5 {
  font-size: 18px;
}

h4.s1 {
  font-size: 12px;
  letter-spacing: 20px;
  text-transform: uppercase;
  font-weight: 500;
}

p.lead {
  font-size: 18px;
  line-height: 1.7em;
  margin-top: 0;
  font-weight: 400;
}

p.button {
  margin-bottom: 10px;
}

.btn-main {
  display: block;
  width: max-content;
  text-align: center;
  color: #fff !important;
  background: $color;
  border-radius: 25px;
  letter-spacing: normal;
  outline: 0;
  font-weight: 800;
  text-decoration: none;
  padding: 8px 40px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  box-shadow: 2px 2px 20px 0px rgba(131, 100, 226, 0);
  transition: all 0.3s ease;
  @include gradient();

  &:hover {
    box-shadow: 2px 2px 20px 0px $color;
    transition: all 0.3s ease;
  }

  &.inline {
    display: inline-block;
    margin-right: 15px;

    &.white {
      color: $color !important;
      background: $white;
    }
  }
}

/* dropdown */
.dropdown-custom.btn {
  font-size: 14px;
  border: 0px;
  position: relative;
  top: -1px;
  overflow: unset !important;
  letter-spacing: normal;
  font-weight: 800;
  padding: 0 20px 0 0;
  background: none !important;

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 19px;
    width: max-content;
    height: auto;
    padding: 30px 40px;
    background: 0;
  }

  &::after {
    margin-left: 5px !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &:hover {
    color: $color;
  }
}

.dropdown-toggle::after {
  content: "\f078";
  font-family: FontAwesome;
  color: rgba(255, 255, 255, 0.5);
  padding-left: 2px;
  font-size: 8px;
  top: -1px;
  position: absolute;
  top: 6px;
  right: 5px;
  border: none;
}

.item-dropdown {
  width: max-content;
  position: absolute;
  background: rgba(33, 36, 40, 0.9);
  border-radius: 5px;
  inset: 50px auto auto 0;
  padding: 0 0;
  animation: smoothDrop 0.2s ease;
  -webkit-animation: smoothDrop 0.2s ease;
  box-shadow: 0 4px 20px 0 rgba(10, 10, 10, 0.4);
  z-index: 1;

  .dropdown {
    position: relative;
    text-align: center !important;
    box-shadow: 0 4px 20px 0 rgba(10, 10, 10, 0.4);

    a {
      color: $white !important;
      text-transform: none;
      font-weight: bold;
      letter-spacing: normal;
      display: block;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 8px 20px 8px 20px;
      min-width: 170px;
      width: 100%;
      text-align: left;

      &:hover {
        color: $white !important;
        // background: $color;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.mainside-dropdown-toggle::after {
  font-family: FontAwesome;
  color: rgba(255, 255, 255, 0.5);
  padding-left: 2px;
  font-size: 8px;
  top: -1px;
  position: absolute;
  top: 6px;
  right: 5px;
  border: none;
}

.navbar .mainside {
  position: relative;
  top: 0px;

  a {
    width: 160px;
    display: inline-block;
    color: $black !important;
  }

  &.mobile-view {
    display: none;

    @media (max-width: 1199px) {
      display: inline-block;
    }
  }
}

.mainside-item-dropdown {
  position: absolute;
  top: 40px !important;
  width: 200px;
  padding: 0 !important;
  background: $black;
  right: 15px;
  @include boxshadow();
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0 0;
  border-radius: 5px;
  animation: smoothDrop 0.2s ease;
  -webkit-animation: smoothDrop 0.2s ease;
  box-shadow: 0 4px 20px (0, 0, 0, 0.2);

  @media (max-width: 1200px) {
    right: 0;
  }

  .dropdown {
    position: relative;
    padding: 0 !important;
    text-align: center !important;
    padding: 0 !important;

    a {
      &:first-child {
        border-radius: 5px 5px 0 0 !important;
      }

      &:last-child {
        border-radius: 0 0 5px 5px !important;
      }
    }
  }
}

.SideDrop {
  margin-top: 0px;
  color: $white !important;
  text-transform: none;
  width: 200px !important;
  font-weight: bold;
  letter-spacing: normal;
  display: block;
  border-radius: 0 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: none !important;
  text-align: left;

  &:hover {
    color: $white !important;
    background: $purple !important;
  }

  &:last-child {
    border-bottom: none;
  }
}

.imgslickz {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*navbar*/
.header-top {
  width: 100%;
  border-bottom: 2px solid $white;

  p {
    color: white;
    line-height: 60px;
    margin: 0;
  }
}

.navbar {
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 50px;
  height: auto;
  display: flex;
  align-items: center;
  background: transparent;
  border-bottom: solid 1px rgba(255, 255, 255, 0);
  z-index: 999;
  background-color: transparent;
  transition: all 0.6s ease;

  @media(max-width:1200px){padding: 0 0px;}
  &.white {
    a {
      color: $white;
    }

    .btn {
      color: $white;

      .lines {
        top: 24px;
      }
    }
  }

  .w-100-nav {
    margin: 0;
    width: 100%;
    justify-content: left;
    align-items: center;
    flex-wrap: nowrap;
  }

  .search-link {
    position: relative;
    top: -10px;
  }

  a {
    position: relative;
    font-size: 22px;
    font-weight: 700;
    padding: 0 5px 0 0;
    text-decoration: none !important;
    color: $white !important;
    outline: none;
    transition: all 0.5s ease;

    // @media (max-width: 1600px) {
    //   font-size: 18px;
    // }

    // @media (max-width: 1400px) {
    //   font-size: 16px;
    // }

    &.circle-link {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      border: 1px solid $color;
      padding-top: 5px;
      text-align: center;
      padding-right: 0;
      background-position: 0 0;
      background-size: 200% 200%;
      @include gradient();
      background-image: linear-gradient(to right, var(--tw-gradient-stops));
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 0.5s;

      &:hover {
        background-position: 100% 100%;
      }
    }

    .search-nav-input {
      width: 300px;
      height: 42px;
      border-radius: 5px;
      background-color: white;
      padding-left: 35px;
      border: 1px solid rgba(209, 213, 219, 1);
      background-image: url(../images/search-color-icon.png);
      background-repeat: no-repeat;
      background-position: 10px center;
    }

    &:hover {
      color: $color !important;
      transition: all 0.5s ease;
      @include shadow();
    }

    &.active {
      transition: all 0.5s ease;
    }
  }

  .navbar-title {
    margin-right: auto;
    font-size: 150%;
    padding: 12px 16px;
  }

  .navbar-itemairdrop {
    position: relative;
    display: inline-block;
    position: relative;
    display: inline-block;
    padding: 24px 10px;
    height: max-content;
  }
  
  .navbar-itemairdrop a {
    position: relative;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 10px 20px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
  }
  
  .navbar-itemairdrop a::before,
  .navbar-itemairdrop a::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    animation: pulse 1.5s infinite ease-in-out;
    pointer-events: none;
  }
  
  .navbar-itemairdrop a::before {
    width: 60px;
    height: 60px;
  }
  
  .navbar-itemairdrop a::after {
    width: 80px;
    height: 80px;
  }
  
  @keyframes pulse {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.7;
    }
    50% {
      transform: translate(-50%, -50%) scale(1.3);
      opacity: 0.5;
    }
    100% {
      transform: translate(-50%, -50%) scale(1.6);
      opacity: 0;
    }
  }

  .navbar-item {
    position: relative;
    display: inline-block;
    padding: 30px 10px;
    height: max-content;
    cursor: default;

    .lines {
      position: absolute;
      top: 22px;
      display: block;
      width: 0;
      left: 0;
      border-bottom: 2px solid $color;
      transition: 0.7s;
    }

    &:hover {
      .lines {
        width: 90%;
        transition: 0.7s;
      }
    }
  }

  .market-btn {
    display: inline-block;
    text-align: center;
    color: black !important;
    background: #fff;
    border-radius: none !important;
    letter-spacing: normal;
    outline: 0;
    font-weight: 800;
    text-decoration: none;
    padding: 6px 20px;
    font-size: 14px;
    display: inline-block;
    border-radius: 10px;
    border: none;
    margin-top: 14px;
    cursor: pointer;
    box-shadow: 2px 2px 20px 0px rgb(131 100 226 / 0%);
    transition: all 0.3s ease;

    &:hover {
      background-color: $color;
      color: $white !important;
    }
  }

  .breakpoint__xl-only {
    width: max-content;
    margin-left: auto;
  }

  .logo {
    width: max-content;
    opacity: 1;

    @media (max-width: 1200px) {
      max-width: 180px;
    }

    .navbar-item {
      position: relative;
      top: 2px;
      left: 0px;
      padding: 0;
      width: max-content;
      border-bottom: none;

      a {
        padding: 0;

        .d-3 {
          display: none;
        }
      }
    }
  }

  .menu {
    display: flex;
    justify-content: flex-end;
  }

  .nav-icon {
    display: none;
  }

  #SideMenu,
  .burger-menu.mobile {
    display: none;
  }

  /* ==== Side Menu ==== */
  .Sidemenu {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 999;
    top: 0;
    left:0;
    transform: translateX(-100%);

    .Sidemenu-inner {
      display: inline-block;
      width: 100%;
      height: 100%;
      text-align: center;
      padding: 50px 20px;
      width: 100%;
      max-width: 400px;
      position: relative;
      background-color: #1A2C2A;
      // background: rgb(2,170,176);
      // background: -moz-linear-gradient(top,  rgba(2,170,176,1) 0%, rgba(0,205,172,1) 100%);
      // background: -webkit-linear-gradient(top,  rgba(2,170,176,1) 0%,rgba(0,205,172,1) 100%);
      // background: linear-gradient(to bottom,  rgba(2,170,176,1) 0%,rgba(0,205,172,1) 100%);
      // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02aab0', endColorstr='#00cdac',GradientType=0 );

      .Side-menu-list {
        display: inline-block;
        width: 100%;
        position: relative;
        margin-left: 0 !important;
        padding-left: 0;

        li {
          display: inline-block;
          width: 100%;
          position: relative;
          margin-left: 0 !important;
          padding-left: 0;
          margin-top: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid rgb(255, 255, 255, 0.3);

          a {
            color: $white !important;
            font-weight: bold;
          }

          a:hover {
            color: $color;
          }

          &:last-child {
            border: none;
          }
        }
      }
    }

    .Close-btn {
      position: absolute;
      right: 20px;
      top: 15px;
      color: white;
      border: hidden;
      background-color: transparent;
      font-size: 22px;
      z-index: 2;

      &:hover {
        color: $white;
      }
    }
  }

  .Sidemenu.active {
    transform: translateX(0);
  }

  /* Burger Menu */
  .burger-menu {
    display: inline-block;
    width: 40px;
    height: 30px;
    border: hidden;
    background-color: transparent;
    position: absolute;
    top: 35px;
    width: 30px;
    right: 30px;
    display: block;
    cursor: pointer;
    z-index: 15;
    @include trans();

    @media (max-width: 575px) {
      right: 15px;
    }

    span {
      position: absolute;
      height: 5px;
      border-radius: 25px;
      background-color: $white;
      @include trans();

      &:nth-of-type(1) {
        left: 30%;
        right: 0%;
        top: 0;
      }

      &:nth-of-type(2) {
        right: 0;
        left: 0;
        top: 10px;
      }

      &:nth-of-type(3) {
        left: 0;
        right: 30%;
        top: 20px;
      }
    }

    &:hover span:nth-of-type(1) {
      left: 0;
      right: 0%;
      top: 0;
    }

    &:hover span:nth-of-type(2) {
      right: 0;
      left: 0;
      top: 10px;
    }

    &:hover span:nth-of-type(3) {
      left: 0;
      right: 0;
      top: 20px;
    }
  }

  /* ==== Side Menu ==== */

  @media only screen and (max-width: 1199px) {
    .m-2-hor {
      padding: 0px 2%;
    }

    .menu {
      display: block;
      padding: 15px 0 10px;
    }

    .clsprofile {
      right: 0px !important;
      top: -18px;
      padding: 0;
      margin-top: 0px !important;
    }

    &.navbar {
      position: relative;
      padding-top: 15px;
      padding-bottom: 15px;

      @media (max-width: 1200px) {
        padding-top: 0;
        padding-bottom: 0;
      }

      .logo {
        display: inline-block;
      }

      .search {
        display: inline-block;
        position: relative;
        top: -9px;

        #quick_search {
          width: 100%;
        }
      }

      .mainside {
        position: absolute;
        right: 90px;
        top: 25px;
        width: unset !important;

        @media (max-width: 575px) {
          right: 70px;
          top: 30px;
        }
      }

      .dropdown-custom.btn {
        color: $white;
        width: 100%;
        text-align: left;

        &::after {
          float: right;
          font-size: 0.9rem;
        }
      }
    }

    .w-100-nav {
      display: block;
      padding: 11px 2%;

      @media (max-width: 1200px) {
        padding-top: 15px;
        padding-bottom: 0;
      }

      @media (max-width: 575px) {
        padding: 0;
      }
    }

    .navbar-item {
      display: block;
      padding: 15px 0;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .nav-icon {
      position: absolute;
      top: 37px;
      right: 25px;
      display: block;
      width: 20px;
      height: 10px;
      padding-top: 13px;
      line-height: 0;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
      z-index: 9999;
    }

    .bg-nav-icon {
      width: 32px;
      height: 24px;
      background: rgba(255, 255, 255, 0);
      border-radius: 2px;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
    }

    #nav-icon span,
    .nav-icon span {
      display: none;
      position: absolute;
      top: 5px;
      left: -40px;
      font-size: 0.62rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    .menu-line {
      position: absolute;
      width: 100%;
      height: 2px;
      top: 0px;
      background-color: $white;
    }

    .menu-line1 {
      position: absolute;
      width: 100%;
      height: 2px;
      top: 5px;
      background-color: $white;
    }

    .menu-line2 {
      position: absolute;
      width: 100%;
      height: 2px;
      top: 10px;
      background-color: $white;
    }

    &.sticky {
      position: absolute !important;
    }

    #SideMenu,
    .burger-menu.mobile {
      display: inline-block;
    }

    #SideMenu h1 {
      color: #ffffff !important;
      font-size: 40px;
    }
  }

  @media only screen and (max-width: 641px) {
    &.navbar {
      .search {
        display: none;
      }
    }
  }

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background: white;
    border-bottom: 0;
    transition: all 0.6s ease;

    &.white {
      background: white;
      box-shadow: 0 4px 20px 0 rgba(10, 10, 10, 0.8);

      .btn {
        color: $white;
      }

      a {
        color: $white;
      }
    }
  }

  .search {
    display: flex;
    width: max-content;
    padding-left: 30px;

    #quick_search {
      padding: 4px 11px;
      border: none;
      border-radius: 20px;
      font-size: 15px;
      color: $white;
      background: rgba(255, 255, 255, 0.1);
      width: 274px;
      height: 34px;
      outline: none;
      transition: 0.7s;

      &:hover {
        transition: 0.7s;
        box-shadow: 0px 0px 0px 2px $color;
      }
    }
  }

  .mainside {
    width: max-content;
    padding-left: 0;
    padding-right: 0;

    a {
      text-align: center;
      color: $white !important;
      letter-spacing: normal;
      outline: 0;
      font-weight: 800;
      text-decoration: none;
      padding: 8px 20px;
      font-size: 16px;
      display: inline-block;
      width: 100%;
      border: none;
      cursor: pointer;
      box-shadow: 2px 2px 20px 0px rgba(131, 100, 226, 0);
      transition: all 0.3s ease;
      border-radius: 10px;

      img {
        position: relative;
        top: -3px;
      }

      &.btn-main.cnt {
        color: $white !important;

        @media (max-width: 575px) {
          padding: 8px 9px !important;
          font-size: 12px;
        }
      }

      &:hover {
        box-shadow: inset 2px 2px 20px 0px rgba(255, 255, 255, 0.2);
        color: $white !important;
        background-color: $black;
      }
    }

    a.btn-main {
      margin-top: 0px;
    }
  }
}

/* disable click active */
.navbar-item {
  pointer-events: auto;
}

// .navbar-item a.active {
//   /*pointer-events: none;*/
// }
/* disable click active */
@media (max-width: 1200px) {
  .header-top {
    display: none;
  }

  .navbar .mainside a.btn-main {
    margin-top: 0px;
  }
}

/*** slider ***/
.jumbomain {
  position: relative;
  padding: 0;
  margin: 0;
}

.slider-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;

  .previousButton,
  .nextButton {
    opacity: 0;
    text-decoration: none;
    transition: all 0.3s ease;
  }

  &:hover {
    .previousButton,
    .nextButton {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
}

.slide {
  background-size: cover !important;
}

.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  text-align: center;

  svg {
    display: none;
  }
}

.previousButton::before,
.nextButton::before {
  content: "\f054";
  font-family: FontAwesome;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: normal;
  color: #fff;
  opacity: 0.8;
}

// .previousButton:hover,
// .nextButton:hover {
// }
.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton {
  left: 4%;
}

.nextButton {
  right: 4%;
}

.slider-content {
  text-align: left;
}

.slider-content .inner {
  padding: 0 12%;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  font-weight: 500;
  position: relative;
  overflow: hidden;
  font-size: 11pt;
  letter-spacing: 1px;
  color: $white;
  border: 2px solid $color;
  background: none;
  border-radius: 0;
  padding: 0 44px;
  height: 45px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  z-index: 0;

  span {
    position: relative;
    z-index: 1;
  }

  &::before {
    content: "";
    width: 180%;
    height: 180%;
    top: -100%;
    left: -100%;
    position: absolute;
    background-image: linear-gradient(to left, $color, $color);
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease;
  }

  &:hover::before {
    top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.3s ease;
  }

  &:hover {
    .shine {
      -webkit-animation: sheen 0.6s alternate;
      animation: sheen 0.6s alternate;
    }
  }
}

.slider-content .inner h1 {
  font-weight: 600;
  max-width: 840px;
  color: #fff;
  font-size: 53px;
}

.slider-content .inner p {
  color: #fff;
  font-size: 40px;
  line-height: 1.3;
  max-width: 640px;
  margin-bottom: 30px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #fff;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #fff;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {
  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  }
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }

  .slider-content .inner p {
    font-size: 21px;
  }
}

.slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide section * {
  transition: all 0.3s ease;
}

.slide section img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0;
}

.slide section span {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0;
}

.slide section span strong {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.slide.animateOut h1 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.slide.animateOut p {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.slide.animateOut section span {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.slide.animateOut section span strong {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/*** breadcumb ***/
.breadcumb {
  border-radius: 0;
  margin: 0;
  padding: 0 0;
  background-size: cover;
  width: 100%;
  position: relative;

  // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  // &::before {
  //   content: "";
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   background: linear-gradient(to bottom, $black, rgba(0, 0, 0, 0.1));
  //   opacity: 0.5;
  // }
  &.no-bg::before {
    content: unset;
  }

  .mainbreadcumb {
    // background-color: white;
    padding: 200px 0 50px;

    @media (max-width: 1200px) {
      padding: 50px 0 50px;
    }

    h1 {
      margin-bottom: 0;
      color: $white;
    }

    p {
      color: $white;
      margin-top: 15px;
    }

    .list {
      position: relative;
      bottom: -20px;
      display: flex;
      justify-content: right;
      font-size: 0.86rem;
      font-weight: 400;
      letter-spacing: 4px;

      .dash {
        padding: 0 10px;
      }

      .link {
        color: $white;
        font-weight: 500;
        line-height: 1.3;
        letter-spacing: 1px;
        margin: 0;
        padding: 0;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          color: $color;
          transition: all 0.3s ease;
        }
      }
    }
  }

  .homebread {
    width: 100%;
    padding: 180px 0 140px;

    .heading {
      line-height: 1.3;
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 15px;
    }

    .subheading {
      font-size: 2.1rem;
      margin-bottom: 0;
    }

    .content {
      max-width: 500px;

      margin-bottom: 0;
      margin-bottom: 30px;
    }
  }

  &.h-vh {
    height: 100vh;
    display: flex;
    align-items: center;
    background-position: center;
    background-size: cover;
  }
}

/* home */
.subheading {
  font-size: 1.3rem;
  margin-bottom: 15px;
}

.heading {
  font-size: 2.3rem;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
  letter-spacing: 1px;
}

.content {
  margin-bottom: 0.5rem;
}

.col-feature {
  display: block;

  .sub-color {
    color: $color;
    font-size: 1.6rem;
    font-weight: 700;
  }

  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    margin: 5px 0;
  }
}

.border-radius {
  border-radius: 0px;
  box-shadow:
    0 10px 12px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: none;
    transition: all 0.3s ease;
  }
}

/* icon scroll */
.icon-scroll-wraper {
  position: absolute;
  width: 100%;
  bottom: 5vh;
  left: 0;
}

.icon-scroll {
  position: relative;
  margin: 0 auto;
}

.icon-scroll:before {
  position: absolute;
}

.icon-scroll {
  width: 26px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.3);
  z-index: 1;
  border-radius: 25px;
  height: 38px;
}

.icon-scroll:before {
  content: "";
  width: 3px;
  height: 6px;
  background: rgba(255, 255, 255, 0.6);
  left: 12px;
  top: 8px;
  border-radius: 4px;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
  animation-name: scroll;
}

@-webkit-keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

.features {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 60px;
  overflow: hidden;

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    position: relative;
    text-align: center;

    .heading {
      color: $color;
      font-weight: 500;
      font-size: 1.2rem;
      margin-bottom: 10px;
    }

    .con-text {
      font-size: 0.9rem;
    }

    .link {
      width: max-content;
      font-weight: 500;
      position: relative;
      overflow: hidden;
      font-size: 11pt;
      letter-spacing: 1px;
      color: #fff;
      border: 2px solid $color;
      background: none;
      border-radius: 0;
      padding: 0 44px;
      height: 45px;
      line-height: 40px;
      margin: 20px auto 0;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background: $color;
        border: 2px solid rgba(0, 0, 0, 0);
        transition: all 0.3s ease;

        .shine {
          -webkit-animation: sheen 0.6s alternate;
          animation: sheen 0.6s alternate;
        }
      }
    }
  }
}

/*** footer ***/
#scroll-to-top {
  cursor: pointer;
  width: max-content;
  height: max-content;
  position: fixed;
  right: 10px;
  z-index: 999;

  &.init {
    bottom: -60px;
    transition: all 0.3s ease;
  }

  &.show {
    bottom: 15px;
    transition: all 0.3s ease;
  }

  div {
    font-size: 0.8rem;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 30px;
    background: $color;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.1);
      transition: all 0.3s ease;
    }

    i {
      font-style: normal;

      &:before {
        font-family: "FontAwesome";
        font-size: 18px;
        content: "\f106";
        color: #fff !important;
        position: relative;
      }
    }
  }
}

/** portofolio **/
.AppContainer {
  color: $white;
  position: relative;
  z-index: 99;
  height: 100%;
  width: 100%;
  background: none;
  // .gallery-container {
  //   .react-photo-gallery--gallery {
  //   }
  // }
}

.react-photo-gallery--gallery {
  margin-top: 15px;
}

.lightbox-portal {
  position: relative;
  z-index: 999;

  h4 {
    font-weight: 700;
  }
}

.btnLright {
  color: $white;
  font-size: 21px !important;
  right: 10px !important;
}

.btnLleft {
  color: $white;
  font-size: 21px !important;
  left: 10px !important;
}

.closeL {
  font-size: 21px !important;
  position: absolute;
  top: 10px;
  right: 10px;
}

.ConMainGimg {
  border-radius: 5px;
  overflow: hidden;
}

.MainGimg {
  transform: scale(1);
  transition: 0.7s;

  .overlayCap {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    width: 100%;
    bottom: 15px;
    font-weight: 500;
    color: $white;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.7s;
    outline: none;
    z-index: 1;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: $color;
      z-index: 0;
      opacity: 0.8;
    }

    span {
      position: relative;
      z-index: 2;
    }
  }

  &:hover {
    transform: scale(1.1);
    box-shadow:
      0 10px 12px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: 0.7s;
    z-index: 2;

    .overlayCap {
      opacity: 1;
    }
  }
}

/** contact **/
.form-side {
  border-radius: 4px;
}

.text-side {
  padding: 15px 0;
  margin-top: 15px;

  .address {
    padding-top: 15px;
    line-height: 1.6;

    .heading {
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 1px;
    }

    .list {
      position: relative;
      max-width: 320px;
      display: block;
      padding: 5px 0 15px;
      padding-left: 40px;

      i {
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 1.3rem;
        display: inline-block;
      }

      a {
        color: $white;
        text-decoration: none;
        transition: all 0.3s ease;

        &:hover {
          color: $color;
          transition: all 0.3s ease;
        }
      }
    }
  }
}

.formcontact {
  display: block;
  width: 100%;

  label {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    font-size: 0.9rem;
    width: 100%;
    padding: 8px 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 15px;
  }

  textarea {
    font-size: 0.9rem;
    width: 100%;
    height: 195px;
    padding: 8px 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 15px;
  }

  .msg-error {
    font-size: 0.87rem;
    opacity: 0.8;
    padding: 5px 0;
  }

  button {
    position: relative;
    overflow: hidden;
    font-size: 11pt;
    color: $white;
    background: none;
    border: 2px solid $color;
    border-radius: 0;
    padding: 8px 50px;
    margin-top: 15px;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    z-index: 0;

    span {
      color: $white;
      position: relative;
      z-index: 3;
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-image: linear-gradient(to left, $color, $color);
      opacity: 0;
      z-index: 1;
      transition: all 0.3s ease;
    }

    &:hover::before {
      opacity: 1;
      transition: all 0.3s ease;
    }

    &:hover {
      .shine {
        -webkit-animation: sheen 1s alternate;
        animation: sheen 1s alternate;
      }
    }
  }
}

#success,
#failed {
  &.show {
    display: block !important;
    margin-bottom: 15px;
  }

  &.hide {
    display: none;
  }
}

#buttonsent.show {
  pointer-events: none;
}

#buttonsent {
  width: max-content;
  padding: 8px 30px;
}

.padding40.box-rounded.mb30 {
  padding: 40px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.05);

  &.text-light {
    color: $white;
    background: rgba(255, 255, 255, 0.05);
    margin-bottom: 0;

    h3 {
      color: $white;
    }

    .btn {
      color: $white;
    }
  }
}

address {
  margin-top: 20px;
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;

  &.s1 span {
    display: block;
    padding: 0;
    font-size: 15px;
    line-height: 1.7em;
    margin-bottom: 5px;

    i {
      color: $color;
      margin-top: 5px;
      margin-left: 5px;
      margin-right: 15px;
      font-size: 15px;
    }

    .btn {
      display: inline-block;
      border: 0;
      color: $color;
      padding: 0;
      margin: 0;

      &:hover {
        background: none;
        padding: 0;
      }

      &:before {
        content: unset;
      }
    }
  }
}

/*** news ***/
.post-content {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

.post-image {
  position: relative;

  img {
    width: 100%;
    margin-bottom: 0px;
  }
}

.post-text {
  padding-top: 20px;

  .p-tagline,
  .p-title {
    background: $color;
    color: $white;
    position: absolute;
    top: 20px;
    left: 20px;
    border-radius: 3px;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 10px;
    padding: 7px 10px 6px 10px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  .p-date {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 10px;
    font-weight: 500;
  }

  h4 {
    margin-top: 10px;
    margin-bottom: 10px;

    span {
      color: $white;
      text-decoration: none;
      font-size: 22px;
      font-weight: bold;
    }
  }
}

.pagination {
  margin: 0 auto;
  font-weight: 500;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.pagination li .a {
  font-size: 14px;
  color: $white;
  border: solid 1px rgba(255, 255, 255, 0.1);
  border-right: none;
  background: none;
  padding: 15px 20px 15px 20px;
  border-radius: 0;
  cursor: pointer;
}

.dark-scheme .pagination li .a {
  border-color: rgba(255, 255, 255, 0);
  background: #21273e;
}

.pagination li:last-child .a {
  border-right: solid 1px rgba(255, 255, 255, 0.1);
}

.dark-scheme .pagination li:last-child .a {
  border-right-color: rgba(255, 255, 255, 0.1);
}

.pagination > .active > .a {
  color: #ffffff;
  background: $color;
}

/*** create file ***/
.d-create-file {
  padding: 50px;
  border-radius: 10px;
  border: dashed 3px #dddddd;
  text-align: center;
  background-color: white;

  #get_file {
    margin: 0 auto;
  }

  .browse {
    position: relative;
    width: max-content;
    height: auto;
    margin: 0 auto;
    cursor: pointer;

    #upload_file {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &:hover {
      .btn-main {
        box-shadow: 2px 2px 20px 0px $color;
        transition: all 0.3s ease;
      }
    }
  }
}

.collect-create-logo {
  padding: 40px;
  border-radius: 10px;
  border: 2px dotted rgba(255, 255, 255, 0.2);
  text-align: center;
  width: 100%;

  p {
    text-align: center;
    color: $white;
  }

  #file_name {
    .get_upload_file_logo {
      border-radius: 50%;
      height: 100px;
      width: 100px;
      object-fit: fill;
    }
  }

  #get_file {
    margin: 0 auto;
  }

  .browse {
    position: relative;
    width: max-content;
    height: auto;
    margin: 0 auto;
    cursor: pointer;

    #upload_file {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &:hover {
      .btn-main {
        box-shadow: 2px 2px 20px 0px $color;
        transition: all 0.3s ease;
      }
    }
  }
}

.collect-create-feature-image {
  padding: 40px;
  border-radius: 10px;
  border: 2px dotted rgba(255, 255, 255, 0.2);
  text-align: center;
  width: 100%;

  p {
    text-align: center;
    color: $white;
  }

  #file_name {
    .get_upload_file_feature_image {
      border-radius: 5px;
      height: 100px;
      width: 200px;
      object-fit: contain;
    }
  }

  #get_file {
    margin: 0 auto;
  }

  .browse {
    position: relative;
    width: max-content;
    height: auto;
    margin: 0 auto;
    cursor: pointer;

    #upload_file {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &:hover {
      .btn-main {
        box-shadow: 2px 2px 20px 0px $color;
        transition: all 0.3s ease;
      }
    }
  }
}

.collect-create-banner-image {
  padding: 40px;
  border-radius: 10px;
  border: 2px dotted rgba(255, 255, 255, 0.2);
  text-align: center;
  width: 100%;

  p {
    text-align: center;
    color: $white;
  }

  #file_name {
    .get_upload_file_banner_image {
      border-radius: 5px;
      height: 200px;
      width: 300px;
    }
  }

  #get_file {
    margin: 0 auto;
  }

  .browse {
    position: relative;
    width: max-content;
    height: auto;
    margin: 0 auto;
    cursor: pointer;

    #upload_file {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &:hover {
      .btn-main {
        box-shadow: 2px 2px 20px 0px $color;
        transition: all 0.3s ease;
      }
    }
  }
}

.social-icon-block {
  background-color: $purple;
  color: $white;

  @media (max-width: 575px) {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.d-grid {
  display: grid;
  place-content: center;
}

/*** activity ***/
.filter__l {
  font-weight: bold;
}

.filter__r {
  float: right;
  cursor: pointer;
}

.activity-filter {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    padding: 8px 10px;
    border: solid 1px rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    width: 48%;
    font-weight: bold;
    cursor: pointer;

    i {
      height: 32px;
      width: 32px;
      padding: 9px 0;
      text-align: center;
      border-radius: 100%;
      background: rgba(255, 255, 255, 0.01);
      color: $color;
      margin-right: 10px;
    }
  }
}

.activity-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 20px;
    padding-right: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    padding-right: 100px;
    background: rgba(255, 255, 255, 0.025);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);

    img {
      width: 80px;
      position: absolute;
      border-radius: 100%;
    }

    &.act_sale {
      img {
        border-radius: 5px;
      }

      &::after {
        content: "\f291";
      }
    }

    &.act_like {
      img {
        border-radius: 5px;
      }

      &::after {
        content: "\f004";
      }
    }

    &.act_offer {
      img {
        border-radius: 5px;
      }

      &::after {
        content: "\f0e3";
      }
    }

    .act_list_text {
      padding-left: 100px;

      .act_list_date {
        display: block;
      }

      h4 {
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 10px;
      }
    }

    &:after {
      content: "\f00c";
      font-family: "FontAwesome";
      margin-right: 15px;
      float: right;
      position: absolute;
      right: 20px;
      top: 30%;
      font-size: 26px;
      color: $color;
      background: rgba(255, 255, 255, 0.025);
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
      height: 48px;
      width: 48px;
      padding: 6px 0;
      text-align: center;
      border-radius: 100%;
    }
  }
}

#sale.active,
#follow.active,
#offer.active,
#like.active {
  background: $color !important;
  color: $white;
}

/*** feature box ***/
.feature-box .inner {
  position: relative;
  overflow: hidden;
  padding: 40px;
  border-radius: 3px;
}

.feature-box.s1 .inner {
  background: white;
}

.feature-box.s1:hover .inner i {
  background: none;
  color: #303030;
}

.text-light .feature-box .inner {
  color: #fff;
}

.feature-box i {
  font-size: 40px;
  margin-bottom: 20px;
}

.feature-box.left i {
  position: absolute;
}

.feature-box.left .text {
  padding-left: 70px;
}

.feature-box.center {
  text-align: center;
}

.feature-box i.circle,
.feature-box i.square {
  display: inline-block;
  padding: 20px;
  font-size: 40px;
  font-weight: 500;
  color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  position: relative;
  z-index: 2;
}

.feature-box i.square {
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

.feature-box i.circle {
  display: inline-block;
  padding: 20px;
  font-size: 40px;
  font-weight: 500;
  color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  position: relative;
  z-index: 2;
}

i.font60 {
  font-size: 60px;
}

.feature-box i.wm {
  font-size: 800px;
  position: absolute;
  top: 0%;
  width: 100%;
  left: 50%;
  transition: 2s ease;

  &:hover {
    transition: 2s ease;
  }
}

.feature-box:hover .inner i.wm {
  transform: scale(1.2);
  color: rgba(0, 0, 0, 0.05);
  z-index: 0;
}

.feature-box:hover .inner i.circle {
  -webkit-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
}

.feature-box.style-3 {
  position: relative;
  overflow: hidden !important;
}

.feature-box.style-3 i {
  display: block;
  text-align: center;
  font-size: 50px;
  color: #ffffff;
  // color: #ffffff;
  // background: $color;
  padding: 0px 0;
  width: 50px;
  height: 50px;
  margin: 0 auto 15px;
  transform: rotate(-20deg);
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.feature-box:hover i {
  transform: rotate(-0deg);
}

.feature-box.style-3 i.wm {
  position: absolute;
  font-size: 240px;
  background: none;
  width: auto;
  height: auto;
  color: rgba(252, 52, 63, 0.1);
  right: 0;
  top: 30%;
}

.feature-box.style-3:hover i.wm {
  -webkit-transform: rotate(-25deg);
  -moz-transform: rotate(-25deg);
  -o-transform: rotate(-25deg);
  transform: rotate(-25deg);
}

.feature-box.f-boxed p {
  font-size: 14px;
  line-height: 20px;
  color: #bfbfbf;
}

.feature-box.f-boxed h4 {
  font-size: 16px;
  margin-top: 0;
  color: #ffffff;
  margin-bottom: 10px;
}

.feature-box.style-4 i {
  position: relative;
  z-index: 2;
  padding: 10px;
  min-width: 60px;
}

.feature-box.style-4 .wm {
  display: block;
  position: absolute;
  font-size: 160px;
  background: none;
  width: 100%;
  height: auto;
  right: 0;
  top: 120px;
  z-index: 2;
  font-weight: bold;
}

.feature-box.style-4 .text {
  margin-top: -50px;
  position: relative;
  z-index: 1;
  background: #ffffff;
  padding: 30px;
  padding-top: 50px;
}

.feature-box.f-boxed {
  overflow: none;
  padding: 43px 25px 0 65px;
  // padding: 50px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  transition: 0.7s;
  width: 285px;
  height: 285px;
  border-radius: 50%;
  background: #3d3d3d;
  margin-bottom: 50px;
  overflow: visible !important;
  margin-left: 127px;
  text-align: center;

  p,
  h4 {
    text-align: left;
  }

  h4 {
    color: #ffffff;
  }

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    background-color: #212428;
    // background-color: #f00;
  }

  &:hover {
    transition: 0.7s;

    span {
      color: $color;
    }
  }
}

.feature-box.f-boxed.right-box {
  float: right;
  padding: 43px 65px 0 25px;
  margin-left: 0;
  margin-right: 127px;

  p,
  h4 {
    text-align: right;
  }

  span {
    left: unset;
    right: -127px;
  }

  &::before {
    left: unset;
    right: 0;
  }
}

.text-light .feature-box.f-boxed {
  background: rgba(0, 0, 0, 0.1);
}

.feature-box.f-boxed:hover {
  color: #ffffff;
  -webkit-box-shadow: 2px 2px 20px 0px rgba(45, 140, 255, 0.05);
  -moz-box-shadow: 2px 2px 20px 0px rgba(45, 140, 255, 0.05);
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
}

.feature-box i.i-circle {
  border-radius: 60px;
}

.feature-box i.i-boxed {
  border-radius: 5px;
}

.feature-box-small-icon.center {
  text-align: center;
}

.dark .feature-box-small-icon .inner:hover > i {
  color: #fff;
}

.feature-box-small-icon .text {
  padding-left: 80px;
}

.feature-box-small-icon.center .text {
  padding-left: 0;
  display: block;
}

.feature-box-small-icon i {
  text-shadow: none;
  font-size: 40px;
  width: 68px;
  height: 68px;
  text-align: center;
  position: absolute;
  text-align: center;
}

.feature-box-small-icon i.hover {
  color: #333;
}

.feature-box-small-icon .border {
  height: 2px;
  width: 30px;
  background: #ccc;
  display: block;
  margin-top: 20px;
  margin-left: 85px;
}

.feature-box-small-icon .btn {
  margin-top: 10px;
}

.feature-box-small-icon.center i {
  position: inherit;
  float: none;
  display: inline-block;
  margin-bottom: 20px;
  border: none;
  font-size: 60px;
  background: none;
  padding: 0px;
}

.feature-box-small-icon.center .fs1 {
  position: inherit;
  float: none;
  display: inline-block;
  margin-bottom: 20px;
  border: none;
  font-size: 60px;
  background: none;
  padding: 0px;
}

.feature-box-small-icon i.dark {
  background-color: #1a1c26;
}

.feature-box-small-icon h4 {
  line-height: normal;
  margin-bottom: 5px;
}

.feature-box-small-icon.no-bg h2 {
  padding-top: 12px;
}

.feature-box-small-icon .number {
  font-size: 40px;
  text-align: center;
  position: absolute;
  width: 70px;
  margin-top: -5px;
}

.feature-box-small-icon .number.circle {
  border-bottom: solid 2px rgba(255, 255, 255, 0.3);
  padding: 20px;
  width: 70px;
  height: 70px;
}

.feature-box-small-icon.style-2 .number {
  font-size: 32px;
  color: #fff;
  padding-top: 22px;
  background: #253545;
  width: 70px;
  height: 70px;
}

.feature-box-small-icon.no-bg .inner,
.feature-box-small-icon.no-bg .inner:hover {
  padding: 0;
  background: none;
}

.f-hover {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

.icon_wallet::before {
  content: "\e100";
  font-family: "ElegantIcons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  transition: 0.7s;
}

/*** column nft ***/
.nft_coll {
  padding-bottom: 10px;
  border-radius: 10px;
  margin: 10px;
  @include trans();

  &:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  }

  img {
    transition: 0.7s;
  }

  &:hover {
    img {
      transform: scale(1.05);
      transition: 0.7s;
    }
  }
}

.nft_wrap {
  position: relative;
  width: 100%;
  height: 200%;
  overflow: hidden;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

.nft_coll_pp {
  width: 60px;
  display: block;
  margin: 0 auto;
  margin-top: -30px;
  margin-bottom: 10px;
  position: relative;

  span {
    cursor: pointer;
  }
}

.nft_coll_pp img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border: solid 5px #ffffff;
  background: #ffffff;
}

.nft_coll_pp i {
  font-size: 10px;
  color: #ffffff;
  background: $color;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  padding: 3px;
  position: absolute;
  top: 5px;
  right: 3px;
}

.nft_coll_info {
  span {
    cursor: pointer;

    h4 {
      color: $black;
      font-weight: 600;
      font-size: 16px;
      text-transform: capitalize;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .color-txt {
    color: $color;
    font-weight: 500;
    display: inline-block;
    font-size: 0.813rem;
    width: 100%;

    img {
      display: inline-block;
      margin-left: 5px;
    }
  }
}

.color-txt {
  color: $color;
}

.nft_coll {
  h4 {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    margin: 0px;
  }

  p {
    color: #b5b7ca;
    font-size: 14px;
    margin: 0;
    margin-top: 0 !important;
  }

  .text-right {
    color: #b5b7ca;
    font-size: 14px;
  }

  .nft_wrap {
    height: 200px;
    width: 100%;
    text-align: center;

    img {
      max-height: 200px;
      max-width: 100%;
      margin: 0 auto;
      border-radius: 15px;
    }
  }
}

.nft_coll span {
  font-size: 14px;
}

.nft_coll_by {
  font-weight: bold;
}

.nft-div {
  width: 30%;
}

.nft {
  width: 100%;

  .slick-prev {
    left: -12px;
    background: white;
    border-radius: 45px;
    display: block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    z-index: 1;
    transition: all 0.3s ease;

    &::before {
      background-image: url(../images/arrow-right.png);
      background-repeat: no-repeat;
      background-position: center center;
      color: transparent;
      background-color: $white;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 45px;
      display: block;
      opacity: 1;
      width: 45px;
      height: 45px;
      line-height: 45px;
      transform: rotate(180deg);
    }

    &:hover {
      background: $white;
      box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.2);
    }
  }

  .slick-next {
    right: -12px;
    background: white;
    border-radius: 45px;
    display: block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    transition: all 0.3s ease;

    &::before {
      // content: "\f054" !important;
      // font-size: 1rem;
      // font-family: FontAwesome !important;
      // font-style: normal;
      // font-weight: normal;
      background-image: url(../images/arrow-right.png);
      background-repeat: no-repeat;
      background-position: center center;
      color: transparent;
      background-color: $white;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 45px;
      display: block;
      opacity: 1;
      width: 45px;
      height: 45px;
      line-height: 45px;
    }

    &:hover {
      background: $white;
      box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.2);
    }
  }

  &:hover {
    .slick-prev,
    .slick-next {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }

  .blockquote {
    font-size: 0.98rem;
    text-align: left;
    margin: 15px 30px 30px;
    display: flex;

    .fa {
      font-size: 1.3rem;
      display: inline-block;
      margin: 5px 15px 0 0;
      color: $white;
      height: max-content;
    }

    p {
      line-height: 1.8;
      display: inline-block;
    }

    .bytesti {
      font-size: 0.9rem;
      font-weight: 400;
    }
  }

  .slick-dots {
    bottom: -40px;

    li.slick-active button::before {
      opacity: 0.75;
      color: $white;
    }

    li button::before {
      color: $white;
    }
  }
}

.nft__item {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  position: relative;
  padding: 0;
  margin: 10px;
  // border-top: solid 1px rgba(255, 255, 255, 1);
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  margin-bottom: 25px;
  background: transparent;
  transition: 0.7s;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);

  h4 {
    color: #ffffff;
    font-weight: bold;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    margin: 0px;
  }

  p {
    color: #b5b7ca;
    font-size: 14px;
    margin: 0;
    margin-top: 0 !important;
  }

  &:hover {
    box-shadow: 2px 2px 30px 0px rgba(10, 10, 10, 0.1);
    transition: 0.7s;
  }
}

.nft__item_info {
  cursor: pointer;
  padding: 15px;
  border-radius: 0 0 15px 15px;
  line-height: 28px;
  background-image: url(../images/blur-bg.png);
  background-size: 100% 100%;
}

.nft__item span h4 {
  font-weight: 800;
  font-size: 15px;
  margin-bottom: 5px;

  height: auto;
}

.nft__item_price {
  font-size: 14px;
  font-weight: 600;
  display: block;
}

.nft__item_price span {
  margin-left: 10px;
  color: $white;
  font-weight: 700;
}

.nft__item_action {
  font-size: 14px;

  span {
    font-weight: bold;
    color: $color;
  }
}

.nft__item_like {
  // position: relative;
  // bottom: 22px;
  font-size: 14px;
  color: #ddd;
  float: right;

  span {
    margin-left: 5px;
    font-size: 12px;
  }

  &:hover i {
    color: #ea1515;
  }
}

/*** countdown ***/
.de_countdown {
  position: relative;
  font-size: 0.8rem;
  position: absolute;
  right: 20px;
  background: white;
  padding: 6px 10px;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border: solid 2px $color;
  z-index: 1;
  color: $white;

  .Clock-days {
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
  }

  .Clock-hours {
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
  }

  .Clock-minutes {
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
  }

  .Clock-seconds {
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
  }
}

.author_list_pp {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  // background: $color;
  // border: 1px solid white;
  margin-left: 15px;
  margin-top: 15px;
  border-radius: 100% !important;
  z-index: 1;
  transition: 0.3s;

  &:hover img {
    padding: 3px;
    box-shadow: $color;
    -webkit-box-shadow: 0px 0px 0px 2px $color;
    transition: 0.3s;
  }
}

.author_list_pp img {
  width: 68px;
  height: 68px;
  // width: 100%;
  border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  -webkit-border-radius: 100% !important;
  position: relative;
  z-index: 1;
}

.author_list_pp i {
  color: #ffffff;
  background: $color;
  font-size: 10px;
  padding: 3px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  z-index: 2;
}

.header_list_pp {
  cursor: pointer;
  // position: absolute;
  display: inline-block;
  width: 40px;
  display: inline-block;
  min-height: 40px;
  height: auto;
  // background: $color;
  // margin-left: 10px;
  // margin-top: -24px;
  border-radius: 100% !important;
  background-color: $color;
  z-index: 1;
  transition: 0.3s;

  &:hover img {
    // box-shadow: $color;
    // -webkit-box-shadow: 0px 0px 0px 2px $color;
    transition: 0.3s;
  }
}

.header_list_pp img {
  width: 100%;
  border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  -webkit-border-radius: 100% !important;
  position: relative;
  z-index: 1;
}

.header_list_pp i {
  color: #ffffff;
  background: $color;
  font-size: 10px;
  padding: 3px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  z-index: 2;
}

.nft__item_wrap {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  justify-content: center;
  @include boxshadow();
  border-radius: 15px 15px 0 0;
}

.nft__item:hover {
  .nft__item_wrap {
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.3);
  }
}

.nft__item_wrap span {
  display: block;
  width: 100%;
}

.nft__item img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

/*** author list ***/
.author_list {
  column-count: 4;
  column-gap: 20px;

  li {
    margin-bottom: 30px;
  }

  .author_list_pp {
    position: absolute;
    display: inline-block;
    width: 50px;
    height: auto;
    margin-left: 10px;
    margin-top: -3px;
    cursor: pointer;
    z-index: 10;

    &:hover {
      img {
        padding: 1px;
        transition: 0.2s ease;
      }
    }

    img {
      transition: 0.2s ease;
    }
  }

  .author_list_info {
    font-weight: bold;
    padding-left: 70px;

    span {
      cursor: pointer;
      color: $white;
    }

    .bot {
      display: block;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.2em;
    }
  }
}

/*** icon box ***/
.icon-box {
  display: block;
  text-align: center;
  border: solid 1px #dddddd;
  padding: 20px 0 20px 0;
  text-decoration: none;
  cursor: pointer;
}

.icon-box.style-2 {
  border: none;
  background: rgba(133, 100, 226, 0.1);
}

.text-light .icon-box {
  border: solid 2px rgba(255, 255, 255, 0.1);
}

.icon-box i {
  display: block;
  font-size: 40px;
  margin-bottom: 10px;
  transition: 0.3s;
  color: $color;
}

.icon-box span {
  display: block;
  color: #606060;
  font-weight: bold;
  font-size: 14px;
  margin-top: 5px;
  line-height: 1.4em;
}

.dark-scheme .icon-box span {
  color: #ffffff;
}

.text-light .icon-box span {
  color: #ffffff;
}

.icon-box:hover {
  color: #ffffff;
  background: $color;
  -webkit-box-shadow: 5px 5px 30px 0px $color;
  -moz-box-shadow: 5px 5px 30px 0px $color;
  box-shadow: 5px 5px 30px 0px $color;
  transition: 0.3s;

  span,
  i {
    color: $white;
  }
}

#tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
}

/*** carousel ***/
.d-carousel {
  position: relative;
  display: flex;
  align-items: center;
}

.nft_pic {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  margin: 0 10px 10px;
  transition: 0.7s;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 1s;
  }

  &:hover {
    img {
      transition: 3s;
      transform: scale(1.2);
    }
  }
}

.nft_pic_info {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  padding: 40px;
  z-index: 1;
  font-size: 28px;
  color: #ffffff;
}

.nft_pic_title {
  display: block;
  font-weight: bold;
}

.nft_pic_by {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.6);
}

.nft-big {
  width: 100%;

  .slick-prev {
    left: 10px;
    display: block;
    width: 40px;
    height: 60px;
    overflow: hidden;
    line-height: 60px;
    z-index: 1;
    transition: all 0.3s ease;

    &::before {
      display: block;
      content: "\f053" !important;
      font-size: 0.9rem;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: normal;
      color: $black;
      position: relative;
      line-height: 60px;
      width: 60px;
      height: 60px;
      padding-left: 20px;
      border-radius: 60px;
      background: $white;
      left: -25px;
      opacity: 1;
    }
  }

  .slick-next {
    right: 9px;
    display: block;
    width: 40px;
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    transition: all 0.3s ease;

    &::before {
      display: block;
      content: "\f054" !important;
      font-size: 0.9rem;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: normal;
      color: $black;
      position: relative;
      line-height: 60px;
      width: 60px;
      height: 60px;
      padding-right: 20px;
      border-radius: 60px;
      background: $white;
      right: -4px;
      opacity: 1;
    }
  }

  &:hover {
    .slick-prev,
    .slick-next {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }

  .blockquote {
    font-size: 0.98rem;
    text-align: left;
    margin: 15px 30px 30px;
    display: flex;

    .fa {
      font-size: 1.3rem;
      display: inline-block;
      margin: 5px 15px 0 0;
      color: $white;
      height: max-content;
    }

    p {
      line-height: 1.8;
      display: inline-block;
    }

    .bytesti {
      font-size: 0.9rem;
      font-weight: 400;
    }
  }

  .slick-dots {
    bottom: -40px;

    li.slick-active button::before {
      opacity: 0.75;
      color: $white;
    }

    li button::before {
      color: $white;
    }
  }
}

/*** filter ***/
.items_filter {
  width: 100%;
  font-size: 14px;
  margin-bottom: 30px;
  margin-top: -14px;

  .dropdownSelect {
    display: inline-block;
    width: 200px;
    position: relative;
    margin-right: 10px;
    margin-bottom: 5px;
    z-index: 2;

    .css-1okebmr-indicatorSeparator {
      background: none;
    }

    &.one {
      z-index: 9;
    }

    &.two {
      z-index: 8;
    }

    &.three {
      z-index: 7;
    }
  }

  &.centerEl {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
}

#form_quick_search {
  display: inline-block;
  margin-right: 0px;
  position: relative;
  top: 14px;

  input[type="text"] {
    padding: 8px 12px;
    width: 200px;
    float: left;
    color: $white;
    border-radius: 5px 0 0 5px;
    border: solid 1px rgba(255, 255, 255, 0.1);
    border-right-color: rgb(221, 221, 221);
    border-right-style: solid;
    border-right-width: 1px;
    border-right: none;
    background: white;
    box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
    font-size: 16px;
  }

  #btn-submit {
    padding: 0;
    margin: 0;
    border: 0;
    background: none;

    i {
      text-align: center;
      font-size: 16px;
      float: left;
      width: 60px;
      background: $purple;
      color: #ffffff;
      display: table-cell;
      padding: 11px 0;
      border-radius: 0 5px 5px 0;
      cursor: pointer;
    }
  }
}

.select1 {
  .css-wenqhu-control {
    background-color: #212428;
    border: 1px solid rgba(255, 255, 255, 0.1);

    & > div > div {
      color: $white;
    }
  }
}

/*** colection ***/
#profile_banner {
  min-height: 360px;
}

.d_coll {
  position: relative;
}

.d_profile {
  margin-bottom: 40px;
}

.profile_avatar {
  display: flex;
}

.d_coll .profile_avatar {
  display: block;
  text-align: center;
}

.profile_avatar img,
.profile_name {
  display: flex;
  align-items: center;

  .profile_username {
    font-size: 16px;
    display: block;
    color: $color;
    text-overflow: ellipsis;
  }
}

.d_coll .profile_avatar img,
.d_coll .profile_name {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.profile_avatar img {
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  width: 150px;
  height: auto;
}

.d_coll .profile_avatar img {
  display: inline-block;
  margin-top: -100px;
  margin-bottom: 30px;
}

.profile_avatar i {
  color: #ffffff;
  background: $color;
  margin: 110px 0 0 110px;
  display: block;
  position: absolute;
  padding: 10px;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

.d_coll .profile_avatar i {
  margin-top: -30px;
}

.d_coll .d_profile_img {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.profile_name {
  margin-left: 20px;
}

.profile_name h4 {
  font-size: 24px;
  line-height: 1.3em;
  color: $white;
}

.profile_wallet {
  font-size: 14px;
  display: block;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
  color: #000000;
  font-weight: normal;
}

.d_coll .profile_wallet {
  display: inline-block;
  float: none;
}

#btn_copy {
  // position: absolute;
  font-size: 12px;
  padding: 4px 10px;
  line-height: 1em;
  border: solid 1px #dddddd;
  display: inline-block;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: none;

  &:hover {
    color: $white;
    background: $color;
    border: 0;
  }
}

.blue-background:hover {
  color: $white !important;
  background: $color;
  border: 0;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.de_nav {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0;
  width: 100%;
  text-align: center;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: url(../images/tab-bg.png);
    background-size: 100% 100%;
    border-radius: 50px;
    opacity: 0.2;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &.left {
    text-align: left;

    li {
      margin-left: 0;
    }
  }

  &.left-align {
    text-align: left;
  }

  &.text-left {
    text-align: left;
    margin: 10px;
  }

  li {
    float: none;
    position: relative;
    display: inline-block;
    margin: 0 5px;

    @media (max-width: 767px) {
      width: 33.33333%;
      float: left;
      margin: 0;
      padding: 0 10px;
    }

    @media (max-width: 575px) {
      width: 50%;
      float: left;
      margin: 0;
    }

    span,
    a {
      position: relative;
      display: inline-block;
      font-size: 16px;
      text-transform: capitalize;
      cursor: poInter;
      border-radius: 50px;
      -webkit-border-radius: 50px;
      color: white;
      background-color: transparent;
      font-weight: 700;
      padding: 15px 45px;
      text-decoration: none;
      opacity: 0.3;

      @media (max-width: 1200px) {
        padding: 15px 35px;
      }

      @media (max-width: 991px) {
        padding: 12px 16px;
        font-size: 14px;
      }

      @media (max-width: 767px) {
        width: 100%;
        text-align: center;
        border-radius: 10px;
        margin-bottom: 15px;
      }

      &:hover {
        color: $color;
        opacity: 1;
      }

      &:before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: -1;
        @include gradient();
        border-radius: 50px;
        opacity: 0;
        @include trans();

        @media (max-width: 767px) {
          border-radius: 10px;
        }
      }
    }

    &.active a,
    &:hover span,
    &:hover a,
    &.active span {
      color: $white;

      &:before {
        opacity: 1;
      }
    }

    &.active {
      a,
      span {
        opacity: 1;
      }

      &.active a,
      &:hover span,
      &:hover a,
      &.active span {
        color: $white;

        &:before {
          opacity: 1;
        }
      }
    }
  }
}

.profile_follower {
  margin-right: 20px;
  font-weight: 600;
}

/*** item details ***/
.item_info {
  padding-left: 10px;

  .de_countdown {
    position: relative;
    display: inline-block;
    margin: 0;
    margin-bottom: 0px;
    margin-left: 0px;
    right: 0;
    border: none;
    padding: 0;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .item_info_counts {
    display: flex;
    align-items: stretch;
    margin-bottom: 20px;

    > div {
      margin-right: 10px;
      font-weight: 400;
      padding: 2px 10px;
      font-size: 14px;
      text-align: center;
      min-width: 80px;
      background: rgba(255, 255, 255, 0.025);
      border-top: solid 1px rgba(255, 255, 255, 0.1);
      border-radius: 3px;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;

      i {
        margin-right: 5px;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  h6 {
    margin-bottom: 15px;
    font-size: 12px;
  }

  .item_author .author_list_info {
    padding-top: 10px;
    padding-left: 70px;
    color: $white;
    font-weight: bold;
  }

  .de_nav {
    text-align: left;
    margin-bottom: 30px;
  }
}

.p_list {
  margin-bottom: 30px;
}

.p_list_pp {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: auto;
  margin-left: 0px;
  margin-top: -3px;
  z-index: 10;

  img {
    width: 100%;
    border-radius: 100% !important;
  }

  i {
    color: #ffffff;
    background: $color;
    font-size: 10px;
    padding: 3px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
  }
}

.p_list_info {
  font-weight: 400;
  padding-left: 70px;

  span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2em;
  }

  b {
    color: $white;
  }
}

img.img-rounded {
  border-radius: 20px;
}

/*** wallet ***/
.box-url {
  position: relative;
  padding: 30px;
  color: #a2a2a2;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: block;
  border-radius: 20px;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.1);
  transition: 0.3s;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
    transition: 0.3s;
  }

  .box-url-label {
    font-weight: bold;
    position: absolute;
    right: 30px;
    color: $white;
    padding: 2px 10px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
  }
}

.mb30 {
  margin-bottom: 30px;
}

.mb20 {
  margin-bottom: 20px;
}

/*** login ***/
.field-set label {
  color: #ffffff;
  font-weight: 500;
}

.form-control-custom {
  padding: 8px;
  margin-bottom: 20px;
  border: none;
  border: solid 1px rgba(255, 255, 255, 0.1);
  background: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  height: auto;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  color: $white;
}

.list.s3 {
  display: inline;
  margin: 0;
  padding: 0;

  li {
    display: inline;
    list-style: none;
    margin-right: 20px;

    span {
      color: $color;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.spacer-10 {
  width: 100%;
  height: 10px;
  display: block;
  clear: both;
}

.align-items-center {
  align-items: center !important;
}

/*** all icon ***/
.demo-icon-wrap-s2 {
  margin-bottom: 30px;

  span {
    display: inline-block;
    text-align: center;
    font-size: 20px;
    margin-right: 20px;
    position: relative;
    color: $color;
    background: rgba(131, 100, 226, 0.1);
    width: 48px;
    height: 48px;
    border-radius: 5px;
    padding: 15px 0;
  }
}

.demo-icon-wrap {
  margin-bottom: 30px;

  i {
    text-align: center;
    font-size: 20px;
    margin-right: 20px;
    position: relative;
    color: $color;
    background: rgba(131, 100, 226, 0.1);
    width: 48px;
    height: 48px;
    border-radius: 5px;
    padding: 15px 0;
  }

  .text-grey {
    display: none;
  }
}

/*** element ***/
.card-header {
  padding: 1rem 1.25rem;

  .btn {
    width: 100%;
    height: 100%;
    text-align: left;
    // color: $general;
    padding: 0;
    margin: 0;
    border: 0;

    &:before {
      content: unset;
    }

    &:focus {
      box-shadow: unset;
    }
  }
}

.card-body {
  padding: 1rem 1.25rem;
}

.sequence {
  .mb30 {
    margin-bottom: 30px;
  }

  .pricing-s1 {
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.025);
    background-size: auto;
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);

    .top {
      padding: 30px;
      text-align: center;

      h2 {
        color: $color;
      }
    }

    .mid {
      padding: 40px;
      text-align: center;
      background: $color;

      .m.opt-1 {
        font-size: 48px;
        font-weight: 500;
      }
    }

    .bottom {
      border-top: 0px;
      text-align: center;

      ul {
        display: block;
        list-style: none;
        list-style-type: none;
        list-style-type: none;
        margin: 30px 0;
        padding: 0px;
        text-align: left;
        overflow: hidden;

        li {
          padding: 10px 30px;
          color: #505050;

          i {
            margin-right: 10px;
            color: $color;
          }
        }
      }
    }

    .action {
      text-align: center;
      padding: 40px 0;
      border-top: solid 1px rgba(255, 255, 255, 0.1);

      .btn-main {
        margin: 0 auto;
      }
    }
  }
}

.skill-bar {
  margin-bottom: 40px;

  &.style-2 {
    .de-progress {
      background: rgba(0, 0, 0, 0.1);
      background-size: auto;
      width: 100%;
      height: 8px;
      background: #ddd;
      margin-top: 20px;
      margin-bottom: 20px;
      border-radius: 8px;
      overflow: hidden;

      .progress-bar {
        background: $color;
        height: 8px;
        box-shadow: none;
      }
    }
  }
}

#tabs1 {
  .nav-tabs {
    font-weight: bold;
    border-bottom: 0;
    margin-bottom: 30px;
  }
}

#tabs2 {
  .nav-tabs {
    font-weight: bold;
    border-bottom: 0;
    margin-bottom: 30px;

    .nav-link {
      &:hover {
        border: 0;
      }
    }
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: $white;
    background: $color;
    border-radius: 4px;
    border: 0;
  }
}

#tabs3 {
  display: flex;
  justify-content: space-between;

  .nav {
    display: inline-block;
    margin-right: 15px;
  }

  .nav-tabs {
    font-weight: bold;
    border-bottom: 0;
    margin-bottom: 30px;

    .nav-link {
      &:hover {
        border: 0;
      }
    }
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: $white;
    background: $color;
    border-radius: 4px;
    border: 0;
  }
}

#tabs4 {
  display: flex;
  flex-direction: row-reverse;

  .nav {
    display: inline-block;
    margin-left: 15px;
  }

  .nav-tabs {
    font-weight: bold;
    border-bottom: 0;
    margin-bottom: 30px;

    .nav-link {
      &:hover {
        border: 0;
      }
    }
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: $white;
    background: $color;
    border-radius: 4px;
    border: 0;
  }
}

.card {
  background: rgba(255, 255, 255, 0.025);
}

.nav-link {
  color: $white;

  &:hover {
    color: $color;
  }
}

/*** filter option ***/
.item_filter_group {
  border: solid 1px rgba(255, 255, 255, 0.075);
  margin-bottom: 25px;
  padding: 30px;
  border-radius: 12px;

  .de_checkbox {
    position: relative;
    display: block;
    padding-left: 25px;

    input[type="checkbox"] {
      display: none;
      appearance: none;
      border: none;
      outline: none;
    }
  }

  .de_form input[type="checkbox"]:checked + label {
    border: none;
  }

  .de_form input[type="checkbox"] + label::before {
    position: absolute;
    content: "";
    display: inline-block;
    margin-right: 10px;
    border: solid 2px rgba(255, 255, 255, 0.2);
    width: 16px;
    height: 16px;
    margin-top: 5px;
    left: 0;
    font-size: 11px;
    padding: 1px 3px 0 3px;
    line-height: 15px;
    border-radius: 4px;
  }

  .de_form input[type="checkbox"]:checked + label::before {
    content: "\f00c";
    color: #ffffff;
    border: rgba(0, 0, 0, 0);
    background: $color;
  }
}

/*** table ***/
.de-table.table-rank {
  color: #ffffff;
  display: table;
  margin-bottom: 0;
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: #dee2e6;

  thead {
    border-style: hidden !important;
    padding-bottom: 15px;
  }

  tr {
    border-bottom: solid 1px rgba(255, 255, 255, 0.1) !important;

    &.spacer-single {
      border-bottom: 0 !important;
    }
  }

  th {
    padding: 30px 0;
  }

  td {
    padding: 30px 0;
  }

  .coll_list_pp {
    position: absolute;
    display: inline-block;
    width: 50px;
    height: auto;
    margin-left: 10px;
    margin-top: -12px;
    z-index: 10;
    left: 0;

    img {
      width: 100%;
      border-radius: 100% !important;
      position: relative;
      z-index: 1;
    }

    i {
      color: #ffffff;
      background: $color;
      font-size: 10px;
      padding: 3px;
      position: absolute;
      right: 0;
      bottom: 0;
      border-radius: 100%;
      z-index: 2;
    }
  }

  tbody th {
    position: relative;
    padding-left: 75px;
  }

  .d-plus {
    color: #34c77b;
  }

  .d-min {
    color: #eb5757;
  }
}

.pagination {
  margin: 0 auto;
  font-weight: 500;
  border-radius: 3px;

  li {
    span {
      border: solid 1px rgba(255, 255, 255, 0.1);
      padding: 15px 20px 15px 20px;
      color: $white;
      cursor: pointer;
    }
  }

  li.active {
    span {
      border-color: $color;
      border-right: none;
      background: $color;
    }
  }
}

#form_sb {
  .col {
    display: flex;
  }

  input[type="text"] {
    padding: 21px 12px 21px 12px;
    width: 85%;
    height: 30px;
    float: left;
    margin-bottom: 0;
    border-radius: 8px 0 0 8px;
    border: none;
    background: rgba(255, 255, 255, 0.7);
  }

  #btn-submit {
    border: none;
    background: none;
    margin: 0;
    padding: 0;

    i {
      text-align: center;
      font-size: 30px;
      float: left;
      width: 100%;
      color: #ffffff;
      background: $color;
      display: table-cell;
      padding: 5px 40px 7px 40px;
      border-radius: 0 8px 8px 0;
    }
  }
}

/*** media all ***/
@media only screen and (max-width: 1199px) {
  .header_list_pp {
    margin-top: 0px;
  }

  .item-dropdown {
    position: relative;
    width: 80%;
    inset: unset;
    padding-top: 10px;
    border: 0;
    background: none;
    box-shadow: unset;

    .dropdown {
      a {
        color: $white !important;
        padding: 10px 10px 10px 0px;
        width: 100%;
        text-align: left;
        left: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  .navbar {
    top: 0;

    &.white a {
      color: $white;
    }
  }

  #myHeader .container {
    max-width: unset !important;
  }
}

@media only screen and (max-width: 1024px) {
  .m-10-hor {
    padding-left: 2%;
    padding-right: 2%;
  }

  .subfooter .de-flex,
  .subfooter .de-flex-col {
    display: block;
  }

  footer.footer-light .subfooter span.copy {
    display: block;
    margin: 20px 0;
  }
}

@media only screen and (max-width: 768px) {
  .breadcumb .mainbreadcumb .list {
    justify-content: left;
    text-align: left;
    margin-bottom: 30px;
  }

  .previousButton,
  .nextButton {
    opacity: 1 !important;
  }

  .xs-hide {
    display: none;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .author_list {
    column-count: 1;
    column-gap: 20px;
  }

  #form_quick_search {
    top: 0;
    width: 100%;

    .col {
      padding-right: 0;
    }
  }

  #form_quick_search input[type="text"] {
    width: 80%;
  }

  #form_quick_search #btn-submit {
    width: 20%;
  }

  #form_quick_search #btn-submit i {
    width: 100%;
  }

  .items_filter .dropdownSelect {
    margin-right: 0;
    width: 96%;
  }

  .item_info {
    margin-top: 30px;
  }

  .profile_avatar {
    display: block;
  }

  .d_profile {
    display: block;

    .de-flex-col {
      display: block;
    }
  }

  .profile_name {
    margin-left: 0;
    margin-top: 20px;
    display: block;
    width: 100%;
  }

  .profile_follow {
    margin-top: 50px;
    width: 100%;
  }

  .nft__item {
    margin: 0 0 30px 0;
  }

  .de_nav.text-left {
    margin-left: 0;
  }
}

.imsettt {
  margin-left: 0% !important;
  width: 99% !important;
  margin-bottom: 20px;
  height: auto;
}

.Topbarsetting {
  max-width: 100%;
  display: flex !important;
  flex-wrap: wrap !important;
  text-overflow: hidden !important;
  justify-content: space-between;
  margin-bottom: 3%;
}

.submitttt {
  width: 50px !important;
}

.changeimg {
  cursor: pointer;
  position: absolute;
  width: 200px;
  height: auto;
  // background: $color;
  border-radius: 100% !important;
  z-index: 1;
  transition: 0.3s;
}

#changePic {
  width: 100%;
  border-radius: 100% !important;
  position: relative;
  z-index: 1;
}

.sidebarsetting {
  margin-top: 30%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-right: 1px solid white;
  height: 80%;
}

.sidebarlinks {
  text-decoration: none;
  width: 89%;
  height: 54px;
  padding-top: 8px;
  color: white;
  // font-weight: bold;
  font-size: 22px;
  margin-bottom: 10px;
  padding-left: 5%;

  &:hover {
    background-color: $color;
    border-radius: 10px;
    color: $black;
    box-shadow: 2px 2px 20px 0px $color;
    transition: all 0.3s ease;
  }

  & > i {
    margin-right: 4%;
  }
}

@media only screen and (min-width: 1203px) {
  .imsettt {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 1203px) {
  .imsettt {
    padding-top: -10px;
  }

  .sidebarsetting {
    display: none;
  }
}

#btnBuy {
  font-size: 18px;
  margin-top: 2px;
}

#btnBuy1 {
  font-size: 18px;
  margin-top: 2px;
  // width: 20px;
  height: 100%;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0; // Override base .form-check
  margin-right: 0;

  // Undo .form-check-input defaults and add some `margin-right`.
  .form-check-input {
    margin-bottom: 0;
    position: static;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }
}

.settingsbtn {
  margin-top: 60px !important;
  margin-right: 20px !important;
  background: $color;
  padding: 5px;
  border-radius: 10px;
  width: 56px;

  & .Secbtn {
    background: none !important;
    border: none !important;
    height: 40px;
    color: #0f0f0f;
    font-size: 28px;
    width: 25px;
    padding-left: 5px;
  }

  & button {
    background: none !important;
    border: none !important;
    height: 40px;
    font-size: 25px;
  }

  & .firstbtn {
    border-right: 1px solid white !important;
  }
}

#contact_form {
  height: auto;
}

#propeerty {
  border: none !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.bottomBorderRed {
  border-bottom: 1px solid $color;
}

// .text-dark:hover {
//   color: $black !important;
//   cursor: pointer;
// }
.propChildd {
  // border-bottom: 1px solid $color;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.propChildd > .child {
  display: flex;
  flex-direction: column;
}

.propChildd > .child .spann {
  display: flex;
  margin-bottom: -5%;
}

.propChildd > .child .spann i {
  color: $color;
  font-size: 20px;
  margin-top: 5px;
  margin-right: 10px;
}

.propChild {
  // border-bottom: 1px solid $color;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
}

.propChild > .child {
  display: flex;
  flex-direction: column;
}

.propChild > .child .spann {
  display: flex;
  margin-bottom: -5%;
}

.propChild > .child .spann i {
  color: $color;
  font-size: 20px;
  margin-top: 5px;
  margin-right: 10px;
}

.propChild > .child2 {
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.propChild > .child2 i {
  cursor: pointer;
  font-size: 40px;
  color: $color;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $color;
}

input:focus + .slider {
  box-shadow: 0 0 1px $color;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#acceptselling {
  border: 1px solid $black !important;
  color: $black !important;
}

.text-red {
  color: $color;
}

.sell-item-btn {
  display: inline-block;
  width: max-content;
  text-align: center;
  color: #fff !important;
  background: $purple;
  border-radius: 10px;
  letter-spacing: normal;
  outline: 0;
  font-weight: 800;
  text-decoration: none;
  padding: 8px 40px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  box-shadow: 2px 2px 20px 0px rgb(131 100 226 / 0%);
  transition: all 0.3s ease;

  &:hover {
    background-color: $color;
  }
}

.cursor-pointor {
  cursor: pointer !important;
}

.author_sell_Nft_image {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #ff343f;

  border-radius: 100% !important;
  z-index: 1;
  transition: 0.3s;
}

.collection_item_desc {
  position: relative;
  bottom: 22px;
  font-size: 14px;
  color: #ddd;

  // float: right;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}

.nft_item_price span {
  // margin-left: 10px;
  color: $white;
  font-size: 20px;
  font-weight: 700;
}

.fontWeight600 {
  font-weight: 600;
}

.fontSize30 {
  font-size: 30px;
}

.fontSize22 {
  font-size: 22px;
}

.fontsize17 {
  font-size: 17px;
}

/* My Css Starts here
======================= */

/* Navigation */
.search-container {
  display: flex;
  width: 350px;
  padding: 0 20px;
  opacity: 0.45;
  @include trans();
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  height: 45px;
  background: -moz-linear-gradient(
    -45deg,
    rgba(106, 106, 106, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(106, 106, 106, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(106, 106, 106, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6a6a6a', endColorstr='#00ffffff', GradientType=1);

  @media (max-width: 1600px) {
    width: 300px;
  }

  @media (max-width: 1400px) {
    width: 220px;
  }

  button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    text-align: center;
    color: $white;
    width: 23px;
    height: 45px;
  }

  input {
    background-color: transparent;
    border: none;
    border-radius: 0;
    margin: 0;
    height: 45px;
    font-weight: bold;
    font-size: 14px;

    &:focus {
      background-color: transparent;
      border: none;
      box-shadow: none;
      color: $white;
    }
  }
}

.navbar {

  .navbar-item {
    padding: 32px 15px 20px;

    @media (max-width: 1400px) {
      padding: 32px 10px 20px;
    }

    @media (max-width: 1199px) {
      padding: 16px 0px 0px;
    }
  }

  .search-item {
    padding-top: 30px;
  }

  &:hover {
    .search-container {
      opacity: 0.6;
    }

    a {
      opacity: 1;
    }
  }

  @media (max-width: 1200px) {
    a {
      opacity: 1;
    }
  }
}

/* Navigation */

/* Home Page Starts Here
======================== */
.landing-header {
  position: relative;
  padding: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 250px;
  padding-bottom: 120px;
  @media(max-width:991px){
    padding-top: 0px;
    padding-bottom: 50px;
  }
  .landing-content-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media(max-width:991px){flex-direction: column;}
  }
  .text-pnl {
    display: inline-block;
    width: 500px;
    max-width: 100%;
    border-radius: 15px;
    position: relative;
    padding: 30px 40px 50px;
    box-shadow: inset 0px -5px 22px rgba(255, 255, 255, 0.15);
    border: 3px solid rgba(27, 70, 62, 1);
    @include trans();
    backdrop-filter: blur(34px);
    --webkit-backdrop-filter: blur(34px);
    background: rgba(15, 182, 138, 0.1);
    @media(max-width:991px){
      margin-bottom: 50px;
      margin-top: 100px;
    }
    @media(max-width:575px){
      padding: 20px 15px 30px;
    }
    .drop-span {
      position: absolute;
      content: "";
      left: -100px;
      top: 25px;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      opacity: 0.7;
      filter: blur(10px);
      background: linear-gradient(177.98deg, #3EAC91 21.39%, #2F7566 79.01%);

    }
    .drop-span-2 {
      position: absolute;
      content: "";
      right: 0px;
      bottom: -60px;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      opacity: 0.7;
      filter: blur(4px);
      background: rgba(62, 172, 145, 1);
    }
    &:after {
      position: absolute;
      content: "";
      right:110px;
      bottom: -20px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      opacity: 0.7;z-index: -1;
      filter: blur(4px);
      background: rgba(62, 172, 145, 1);  
    }

    

  }
  .img-pnl{
    width: calc(100% - 500px);
    text-align:right;
    @media(max-width:1200px){text-align: center;}
    @media(max-width:991px){    width: auto;max-width:80%;}
  }
  &:hover {
    .text-pnl {
      opacity: 1;
    }
  }

  h1 {
    color: $white;
    font-weight: 700;
    line-height: 50px;
    font-size: 45px;
    margin: 0;
    position: relative;
    z-index: 2;

    span {
      color: $white;
      display: inline-block;
      color: $color;
    }
  }

  p {
    margin-bottom: 10px;
    font-size: 18px;
    color: $white;
    position: relative;
    z-index: 2;
  }

  @media (max-width: 991px) {
    min-height: auto;
    height: auto;
    text-align: center;

    .table-cell-center,
    .table-cell {
      height: auto;
      position: relative;
    }

    .text-pnl {
      opacity: 1;
    }

    .big-img {
      max-width: 50%;
      margin: 0 auto;
    }
  }

  @media (max-width: 767px) {
    height: auto;

    h1 {
      font-size: 45px;
    }

    .table-cell-center,
    .table-cell {
      position: relative;
      height: auto;
      display: inline-block;
    }
  }

  @media (max-width: 575px) {
    text-align: center;

    h1 {
      font-size: 32px;
      line-height: 35px;
    }

    p {
      font-size: 16px;
    }

    .big-img {
 
      margin: 0 auto;
    }
  }
}

/* Step List */
.step-list {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 850px;
  margin: 0 auto 20px;

  li {
    width: 33.33333%;
    max-width: 230px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(15, 182, 138, 0.1);
    border: 3px solid rgba(27, 70, 62, 1);
    height: 220px;
    border-radius: 30px;
    img{max-width: 110px;}
    h5 {
      font-size: 22px;
      font-weight: bold;
      color: $white;
      margin-top: 15px;
    }
  }
}

@media (max-width: 991px) {
  .step-list {
    margin-top: 0;
    margin-bottom: 0;
    &:before {
      display: none;
    }
    li {
      max-width: 200px;
      height: 200px;
      img{max-width: 80px;}

    }
  }
}

@media (max-width: 767px) {
  .step-list {
    li {
      max-width: 170px;
      height: 170px;
      img{max-width: 70px;}
      p {
        height: auto;
      }
    }
  }
}

@media (max-width: 575px) {
  .step-list {
    flex-wrap: wrap;
   justify-content: center;
   align-items: center;
    li {
      width: 100%;margin-bottom: 25px;
      
    }
  }
}

/* Step List */
/* Home Page Ends Here
======================== */

/* Small Header 
=============== */
.small-header {
  display: inline-block;
  width: 100%;
  border-radius: 50px;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.14);
  box-shadow: inset 0 -5px 22px rgba(255, 255, 255, 0.15);

  .bg-layer {
    position: absolute;
    content: "";
    left: 0;
    backdrop-filter: blur(11px);
    --webkit-backdrop-filter: blur(11px);
    background-color: rgba(0, 0, 0, 0.04);
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    border-radius: 50px;
    opacity: 0.19;
    background: -moz-linear-gradient(
      -45deg,
      rgba(106, 106, 106, 1) 0%,
      rgba(128, 128, 128, 0) 100%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
      -45deg,
      rgba(106, 106, 106, 1) 0%,
      rgba(128, 128, 128, 0) 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      135deg,
      rgba(106, 106, 106, 1) 0%,
      rgba(128, 128, 128, 0) 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6a6a6a', endColorstr='#00808080', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
  }

  @include trans();
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;

  @media (max-width: 991px) {
    padding: 40px 15px;
  }

  @media (max-width: 767px) {
    padding: 30px 15px;
    border-radius: 20px;
  }

  h1 {
    font-size: 50px;
    margin: 0;

    @media (max-width: 991px) {
      font-size: 40px;
    }

    @media (max-width: 767px) {
      font-size: 28px;
    }

    @media (max-width: 575px) {
      font-size: 22px;
    }
  }

  .drop-span {
    position: absolute;
    content: "";
    left: 15%;
    top: 15%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    opacity: 0.7;
    filter: blur(5px);

    @include gradient;
    @media (max-width: 767px) {
      display: none;
    }
  }

  &:before {
    position: absolute;
    content: "";
    left: 40%;
    bottom: 20%;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    opacity: 0.7;
    filter: blur(5px);

    @include gradient;
    @media (max-width: 767px) {
      display: none;
    }
  }

  &:after {
    position: absolute;
    content: "";
    left: 80%;
    top: 20%;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    opacity: 1;
    filter: blur(3px);

    @include gradient;
    @media (max-width: 767px) {
      display: none;
    }
  }
}

section {
  &:hover .small-header {
    opacity: 1;
  }
}

.breadcrumb {
  background-color: transparent;
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 0;
  padding-bottom: 0;
  margin-top: 0px;

  li {
    display: inline-block;
    font-weight: 700;
    font-size: 18px;
    color: $white;

    @media (max-width: 767px) {
      font-size: 16px;
    }

    @media (max-width: 575px) {
      font-size: 14px;
    }

    a {
      font-weight: 700;
      font-size: 18px;
      color: $white;

      @media (max-width: 767px) {
        font-size: 16px;
      }

      @media (max-width: 575px) {
        font-size: 14px;
      }

      &.active {
        color: $white;
        opacity: 1;
      }

      &:hover {
        color: $color;
      }
    }

    &.active a,
    &.active {
      color: $white;
      opacity: 1;
    }
  }
}

/* Small Header 
=============== */

/* Middle Header 
================= */
.middle-header {
  display: inline-block;
  width: 100%;
  border-radius: 50px;
  @include boxshadow();
  border: 1px solid rgba(255, 255, 255, 0.2);
  @include trans();
  padding: 60px 50px;
  opacity: 1;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media (max-width: 991px) {
    padding: 40px 30px;
  }

  @media (max-width: 575px) {
    text-align: center;
    padding: 30px 15px;
    border-radius: 25px;
  }

  h1 {
    font-size: 50px;
    margin: 0;

    @media (max-width: 991px) {
      font-size: 35px;
    }

    @media (max-width: 767px) {
      font-size: 28px;
    }

    @media (max-width: 575px) {
      font-size: 24px;
    }
  }

  p {
    margin-bottom: 0;
  }

  .drop-span {
    position: absolute;
    left: -80px;
    top: 10%;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    opacity: 1;
    z-index: -1;
    @include gradient;
    filter: blur(4px);
    @media (max-width: 991px) {
      display: none;
    }
  }

  &:before {
    position: absolute;
    content: "";
    right: -120px;
    bottom: -30px;
    width: 350px;
    height: 350px;
    border-radius: 50%;
    z-index: -1;
    @include gradient;
    filter: blur(4px);
    @media (max-width: 991px) {
      display: none;
    }
  }

  &:after {
    position: absolute;
    content: "";
    left: 40%;
    bottom: -70px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $purple;
    z-index: -1;

    @media (max-width: 991px) {
      display: none;
    }
  }
}

section {
  &:hover .middle-header {
    opacity: 1;
  }
}

/* Middle Header 
================= */

/* Create NFT Page
================== */
.color-white {
  color: $white;
}

.inner-page {
  h5 {
    font-size: 20px;
    color: $white;
    margin-bottom: 20px;
  }

  .d-create-file {
    background-color: transparent;
    border: 2px dotted rgba(255, 255, 255, 0.2);
    padding: 25px;

    p {
      color: $white;
    }
  }

  input {
    background-color: transparent;
    height: 45px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 15px;

    &:focus {
      background-color: transparent;
      border-color: $color;
      box-shadow: none;
    }
  }

  select {
    background-color: transparent !important;
    height: 45px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 15px;

    &:focus {
      background-color: transparent;
      border-color: $color;
      box-shadow: none;
    }
  }

  textarea {
    background-color: transparent;
    height: 45px;
    margin-bottom: 15px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    max-width: 100%;
    min-width: 100%;
    min-height: 100px;
    max-height: 100px;

    &:focus {
      background-color: transparent;
      border-color: $color;
      box-shadow: none;
    }

    &.height {
      height: 200px;
      min-height: 200px;
      max-height: 200px;
    }
  }
}

.preview-box {
  margin-bottom: 20px !important;

  .nft__item_wrap span img {
    border-radius: 15px 15px 0 0;
  }

  .nft__item_info {
    background-image: url(../images/blur-bg.png);
    background-size: 100% 100%;

    h4 {
      font-size: 16px;
      font-weight: 700;
      color: #ebebeb;
      margin: 0;
      opacity: 0.3;
    }

    h5 {
      font-size: 20px;
      font-weight: 400;
      color: $white;
      margin: 0;
      opacity: 1;
      margin-top: 5px;
    }

    span {
      font-size: 22px;
      font-weight: 400;
      color: #fff;
      margin: 0;
      opacity: 0.6;
      line-height: 30px;
      text-transform: capitalize;
    }
  }
}

.nft-cheng-post {
  display: inline-block;
  width: 100%;

  li {
    display: inline-block;
    padding: 0 15px;
    float: left;
    width: 33.33333%;

    @media (max-width: 767px) {
      width: 50%;
      margin-bottom: 15px;
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    a {
      display: inline-block;
      width: 100%;
      color: $white;
      font-size: 16px;
      text-align: center;
      padding: 7px 10px;
      border-radius: 5px;
      background-color: #343444;
      opacity: 0.3;

      &.active,
      &:hover {
        box-shadow: inset 0 0 30px rgba(255, 255, 255, 0.2);
        @include gradient();
        opacity: 0.8;
        font-weight: 700;

        @media (max-width: 991px) {
          font-weight: 400;
        }
      }
    }
  }
}

.btn-cntnr .reg-btn {
  margin-right: 15px;
  margin-bottom: 15px;
  display: inline-block;
  border-radius: 10px !important;
}

@media (max-width: 575px) {
  .btn-cntnr {
    text-align: center;

    .reg-btn {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.choose-select-list {
  display: inline-block;
  width: 100%;

  @media (max-width: 575px) {
    text-align: center;
    margin-top: 20px;
  }

  &.more {
    li {
      width: 16.66666666666666667%;

      @media (max-width: 991px) {
        width: 33.333333%;
        margin-bottom: 15px;
      }

      @media (max-width: 767px) {
        width: 50%;
        margin-bottom: 15px;
        max-width: 230px;
      }

      @media (max-width: 575px) {
        width: 100%;
        margin-bottom: 15px;
        float: none;
      }
    }
  }

  li {
    display: inline-block;
    width: 100%;
    float: left;
    padding: 0 10px;

    a {
      display: inline-block;
    }

    @media (max-width: 767px) {
      width: 100%;
      max-width: 230px;
      margin-bottom: 15px;
    }

    @media (max-width: 575px) {
      width: 100%;
      float: none;
      margin-bottom: 20px;
    }
  }
}

.choose-item {
  display: inline-block;
  width: 100%;
  border-radius: 15px;
  opacity: 0.5;

  .img-pnl {
    width: 100%;
    border-radius: 15px 15px 0 0;
    float: left;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1200px) {
      height: 90px;
    }

    @media (max-width: 991px) {
      height: 110px;
    }

    img {
      border-radius: 15px 15px 0 0;
      max-height: 110px;
      width: 100%;

      @media (max-width: 1200px) {
        max-height: 90px;
      }

      @media (max-width: 991px) {
        max-height: 110px;
      }
    }
  }

  .txt-pnl {
    width: 100%;
    border-radius: 0 0 15px 15px;
    float: left;
    text-align: center;
    padding: 20px 10px;
    background-size: 100% 100%;

    h6 {
      font-size: 18px;
      text-transform: capitalize;
      color: $white;
      margin: 0;

      @media (max-width: 1200px) {
        font-size: 16px;
        height: 38.38px;
        overflow: hidden;
      }
    }

    p {
      font-size: 14px;
      color: $white;
      margin: 0;
    }
  }

  &:hover {
    opacity: 1;
  }

  @media (max-width: 767px) {
    opacity: 1;
  }
}

/* Create NFT Page 
================== */

/* ======= My Profile Page ========= */
.upload-profile-picture {
  display: inline-block;
  width: 100%;
  position: relative;
  @include boxshadow();
  border-radius: 20px;
  padding: 30px 20px 40px;
  text-align: center;

  @media (max-width: 991px) {
    margin-bottom: 20px;
  }

  .upload-profile-picture-inner {
    display: inline-block;
    width: auto;
    position: relative;
    background-color: $white;
    border-radius: 20px;

    img {
      border-radius: 20px;
    }
  }
}

.profile-banner-img {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.4);

  @media (max-width: 991px) {
    margin-bottom: 10px;
  }

  @media (max-width: 767px) {
    margin-bottom: 0px;
    margin-top: 20px;
    width: auto;
    margin: 20px auto 0;
  }

  img {
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.profile-info-container {
  width: 100%;

  .profile-container {
    position: relative;
    width: 100%;

    .profile-pic-cntnr {
      img {
        border: 3px solid $white;
      }
    }

    .profile_wallet {
      position: relative;
    }

    .Profile-txt {
      margin-top: 115px;
      margin-left: 50px;
      position: relative;

      @media (max-width: 991px) {
        margin-top: 50px;
      }

      @media (max-width: 767px) {
        margin-left: 20px;
      }

      h4 {
        color: white;
      }

      #btn_copy {
        position: relative !important;
        border-radius: 20% !important;
        background-color: white;
        color: $color;
        font-size: 15px;
        border-radius: 0;
        box-shadow: none;
        border: none;
        padding: 1px 15px;
        right: 0;
      }
    }

    .email-span {
      font-weight: normal;
      display: inline-block;
      width: 100%;
      font-size: 15px;

      @media (max-width: 575px) {
        word-break: break-all;
        display: inline-block;
      }
    }

    .profile-pic-cntnr {
      display: inline-block;
      border-radius: 100%;
      margin-bottom: 20px;

      img {
        border-radius: 100%;
      }
    }
  }

  .profile-text {
    margin-top: 117px;
    margin-left: 26px;
  }

  .profile-text-container {
    display: inline-block;
    float: left;
    text-align: left;
    width: calc(100% - 250px);
    padding: 50px 30px 0;

    p {
      color: $white;
      font-size: 16px;
      margin-bottom: 30px;
    }

    h5 {
      color: $white;
      font-weight: 500;
      font-size: 20px;
    }

    .my-social-list {
      display: inline-block;
      width: 100%;
      padding-left: 0;
      margin-top: 15px;

      li {
        display: inline-block;
        float: left;
        margin-right: 15px;

        a {
          display: inline-block;
          color: $white;
          font-size: 16px;

          i {
            display: inline-block;
            width: 35px;
            height: 35px;
            border-radius: 100%;
            color: $color;
            text-align: center;
            font-size: 20px;
            padding-top: 8px;
            margin-right: 5px;
            background-color: $white;
            @include trans();
          }

          &:hover {
            color: $color;
            text-decoration: none;

            i {
              color: rgba(153, 246, 228, 1);
              background-color: $color;
            }
          }
        }
      }
    }
  }
}

.fb {
  background: rgb(24, 86, 186);
  background: -moz-linear-gradient(
    -45deg,
    rgba(24, 86, 186, 1) 0%,
    rgba(30, 98, 192, 1) 99%
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(24, 86, 186, 1) 0%,
    rgba(30, 98, 192, 1) 99%
  );
  background: linear-gradient(
    135deg,
    rgba(24, 86, 186, 1) 0%,
    rgba(30, 98, 192, 1) 99%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1856ba', endColorstr='#1e62c0', GradientType=1);
}

.tw {
  background: rgb(22, 151, 185);
  background: -moz-linear-gradient(
    -45deg,
    rgba(22, 151, 185, 1) 0%,
    rgba(26, 109, 189, 1) 99%
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(22, 151, 185, 1) 0%,
    rgba(26, 109, 189, 1) 99%
  );
  background: linear-gradient(
    135deg,
    rgba(22, 151, 185, 1) 0%,
    rgba(26, 109, 189, 1) 99%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1697b9', endColorstr='#1a6dbd', GradientType=1);
}

.dixcrd {
  background: rgb(164, 27, 185);
  /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    rgba(164, 27, 185, 1) 0%,
    rgba(62, 77, 189, 1) 99%
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(164, 27, 185, 1) 0%,
    rgba(62, 77, 189, 1) 99%
  );
  background: linear-gradient(
    135deg,
    rgba(164, 27, 185, 1) 0%,
    rgba(62, 77, 189, 1) 99%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a41bb9', endColorstr='#3e4dbd', GradientType=1);
}

.social-btn {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 20px;
  padding: 12px 35px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
  color: $white;

  &:hover {
    color: $white;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5);
  }
}

.pci-input {
  max-width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0 !important;
}

.span-space {
  color: $white;
  margin-bottom: 10px;
  display: inline-block;
}

.profile-banner {
  display: inline-block;
  width: 100%;
  position: relative;
  border-radius: 15px;
  padding-top: 150px;
  margin-bottom: 00px;
  text-align: center;

  @media (max-width: 1200px) {
    padding-top: 50px;
  }

  .banner {
    display: inline-block;
    position: relative;

    background-size: 100% 100%;

    // background-size: cover;

    background-repeat: no-repeat;
    background-position: center center;

    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 325px;

    border-radius: 15px;

    .share-list-pnl {
      position: absolute;
      content: "";
      text-align: right;
      left: 0;
      right: 0;
      top: 0;
      height: 60px;
      opacity: 1;
      background-image: url(../images/blur-bg.png);
      background-size: 100% 100%;
      border-radius: 15px 15px 0 0;

      @media (max-width: 575px) {
        text-align: center;
        height: 40px;
      }

      .share-list-list {
        margin-right: 10px;
        z-index: 10;

        @media (max-width: 575px) {
          margin-right: 0;
        }

        li {
          margin-right: 25px;
          line-height: 60px;
          z-index: 5;

          @media (max-width: 767px) {
            margin-right: 15px;
            margin-left: 15px;
          }

          @media (max-width: 575px) {
            line-height: 40px;
          }
        }
      }
    }

    img {
      border-radius: 15px;
      max-height: 350px;
    }
  }
}

.profile-image-holder {
  position: relative;
  text-align: center;
  top: -60px;

  @media (max-width: 575px) {
    top: 0;
    margin-top: 30px;
  }

  .img-pnl {
    border-radius: 30px;
    position: relative;
    display: inline-block;
    width: 160px;
    height: 160px;

    img {
      border-radius: 30px;
      width: 100%;
      height: 100%;
      max-height: 160px;
    }

    .check-span {
      position: absolute;
      right: 0px;
      bottom: -5px;
      display: flex;
      @include gradient();
      width: 35px;
      height: 35px;
      border-radius: 50%;
      font-size: 18px;
      justify-content: center;
      align-items: center;
      color: $white;
    }
  }

  .text-pnl {
    display: inline-block;
    width: 100%;
    padding: 0 15px;

    h2 {
      font-size: 24px;
      font-weight: bold;
      color: $white;
      margin-bottom: 5px;
      margin-top: 15px;
      text-transform: capitalize;
    }

    h6 {
      font-size: 16px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.4);
    }

    p {
      font-size: 16px;
      color: $white;
      font-weight: 400;
      margin-bottom: 10px;
      @include boxshadow();
      border-radius: 25px;
      display: inline-block;
      padding: 5px 10px;

      img {
        margin-top: -3px;
        margin-right: 10px;
      }
    }

    .btn-more-less {
      font-weight: bold;
      font-size: 18px;
      color: $purple;
      cursor: pointer;
    }

    .user-social-contacts {
      display: inline-block;
      width: 100%;
      text-align: center;

      a {
        display: inline-block;
        margin: 0 15px;
        font-size: 22px;
        color: $white;

        &:hover {
          color: $purple;
        }
      }
    }
  }
}

.share-list-list {
  display: inline-block;
  margin: 0;

  li {
    display: inline-block;

    a {
      font-size: 20px;
      color: $white;

      &:hover {
        color: $purple;
      }
    }
  }
}

section.p-t-0 {
  padding-top: 0 !important;
}

.k-list {
  display: inline-block;
  width: auto;
  position: relative;
  padding: 50px 80px;
  border-radius: 50px;
  text-align: center;
  @include boxshadow();
  margin-bottom: 20px;
  @include trans();

  @media (max-width: 991px) {
    padding: 30px 15px;
  }

  &:before {
    position: absolute;
    content: "";
    left: 51%;
    right: 51%;
    bottom: 51%;
    top: 51%;
    @include gradient();
    border-radius: 50px;
    @include boxshadow();
    opacity: 0;
    @include trans();
  }

  li {
    display: inline-block;
    padding: 0 30px;
    position: relative;

    @media (max-width: 991px) {
      width: 50%;
    }

    @media (max-width: 991px) {
      width: 100%;
    }

    &:not(:first-child):before {
      position: absolute;
      content: "";
      left: 0;
      top: 10px;
      bottom: 10px;
      width: 1px;
      background-color: rgba(255, 255, 255, 0.1);

      @media (max-width: 991px) {
        display: none;
      }
    }

    h3 {
      font-size: 50px;
      font-weight: bold;
      columns: $white;
      margin: 0;
    }

    span {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.2);
      font-weight: 700;
    }

    &.active,
    &:hover {
      h3 {
        color: #3EAC91;
      }
    }
  }

  &:hover {
    &:before {
      opacity: 1;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
    }
  }
}

.nrml-txt {
  p {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.3);
    font-weight: bold;
    max-width: 50%;
    margin: 30px auto;

    &:hover {
      color: rgba(255, 255, 255, 1);
    }

    @media (max-width: 991px) {
      max-width: 70%;
    }

    @media (max-width: 767px) {
      max-width: 90%;
    }

    @media (max-width: 575px) {
      max-width: 100%;
    }
  }
}

.activity-pnl {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #343444;
  position: relative;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 15px;

  &:before {
    position: absolute;
    content: "";
    opacity: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: 15px;
    z-index: 0;
    @include gradient();
    @include trans();
  }

  &:hover:before {
    opacity: 1;
  }

  .left-pnl {
    display: flex;
    position: relative;
    z-index: 2;

    @media (max-width: 575px) {
      display: inline-block;
    }
  }

  .right-pnl {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
  }

  .img-pnl {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 15px;
    background-color: #7a798a;

    img {
      width: 100px;
      border-radius: 15px;
      height: 100%;
    }
  }

  .txt-pnl {
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: 575px) {
      padding-left: 0;
      padding-right: 0;
      padding-top: 10px;
    }

    h3 {
      color: $white;
      font-size: 20px;
      font-weight: bold;
      margin: 0;
      margin-top: 3px;
    }

    p {
      color: #ebebeb;
      font-size: 14px;
      margin: 0;
    }

    span {
      color: #ebebeb;
      font-size: 14px;
      margin: 0;
    }
  }

  .cart-btn {
    display: flex;
    font-size: 20px;
    color: $white;
    @include gradient();
    border-radius: 50%;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
  }
}

.filter-select-listing {
  display: inline-block;
  width: 100%;

  @media (max-width: 575px) {
    text-align: center;
  }

  li {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;

    @media (max-width: 575px) {
      font-language-override: none;
      margin-left: 10px;
      margin-right: 10px;
    }

    a {
      width: auto;
      padding: 5px 15px 3px;
      border-radius: 25px;
      color: $white;
      font-size: 12px;
      background-color: #343444;

      i {
        font-size: 12px;
        margin-right: 3px;
      }

      &:hover {
        background-color: $purple;
      }
    }
  }
}

.clear-btn {
  display: inline-block;
  font-size: 16px;
  color: $purple;
  font-weight: bold;

  &:hover {
    color: $color;
  }
}

/* ======= My Profile Page ========= */

/* Connect wallet Starts Here
============================= */
.wallet-connect-pnl {
  display: inline-block;
  width: 100%;
  padding: 40px 30px 30px;
  background-color: #343444;
  color: $white;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
  position: relative;
  max-width: 350px;
  margin: 100px auto 20px;

  img {
    max-width: 60px;
    position: relative;
    z-index: 1;
  }

  h5 {
    font-size: 18px;
    font-weight: 700;
    color: $white;
    margin-bottom: 15px;
    margin-top: 20px;
    position: relative;
    z-index: 1;
  }

  p {
    font-size: 14px;
    position: relative;
    z-index: 1;
  }

  .reg-btn,
  button {
    position: relative;
    z-index: 1;
  }

  &:before {
    position: absolute;
    content: "";
    left: 0%;
    top: 0%;
    bottom: 0%;
    right: 0%;
    border-radius: 5px;
    opacity: 0;
    z-index: 0;
    @include trans();

    @include gradient;
    filter: blur(4px);  }

  &.active {
    &:before,
    .reg-btn,
    button {
      opacity: 1;
    }
  }

  &:hover {
    &:before,
    .reg-btn,
    button {
      opacity: 1;
    }
  }
}

/* Connect wallet Ends Here
=========================== */


/* NFT Starts here
================== */
.nft-post {
  display: inline-block;
  width: 100%;
  position: relative;
  max-width: 300px;
  margin: 0 auto 20px;

  .heart-span {
    position: absolute;
    right: 0px;
    top: 20px;
    background-color: transparent;
    color: $white;
    font-size: 14px;
    border-radius: 10px 0 0 10px;
    padding: 6px 15px 5px 15px;
    left: unset;
    z-index: 10;

    i {
      margin-right: 5px;
    }

    &:hover {
      color: $color;
    }
  }

  .nft-inner {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    border-radius: 15px;
    background-color: rgba(0,0,0,0.41);
    backdrop-filter: blur(20px);

    // &:before {
    //   position: absolute;
    //   content: "";
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   top: 0;
    //   background-color: #010114;
    //   opacity: 0.4;
    //   z-index: 0;
    //   border-radius: 15px;
    // }
  }

  .img-pnl {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-radius:0 0 15px 15px;
    height: 280px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    z-index: 1;
    opacity: 0.7;

    img {
      border-radius: 15px 15px 0 0;
      max-height: 280px;
      margin: 0 auto;
    }

    .btn-cntnr {
      position: absolute;
      width: 100%;
      text-align: center;
      padding: 0 10px;
      height: 100%;
      justify-content: center;
      align-items: center;
      z-index: 2;
      display: none;

      button {
        box-shadow: inset 0 0 30px rgba(255, 255, 255, 0.6);

        &:hover {
          box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.2);
        }
      }
    }

    .bid-time-pnl {
      position: absolute;
      right: 0;
      bottom: 40px;
      @include boxshadow();
      z-index: 2;
      display: none;
      border-radius: 10px 0 0 10px;
      padding: 7px 15px;
      background: black;

      h3 {
        font-size: 16px;
        margin: 0;
        font-weight: 700;
        color: white;
      }
    }
  }

  .text-pnl {
    display: inline-block;
    width: 100%;
    position: relative;
    border-radius: 15px 15px 0 0;
    padding: 40px 15px 15px;
    z-index: 3;
    

    h6 {
      font-size: 16px;
      font-weight: 700;
      color: #ebebeb;
      margin: 0;
      opacity: 0.3;
    }

    h2 {
      font-size: 30px;
      font-weight: 400;
      color: $white;
      text-transform: capitalize;
      margin-top: 5px;
      margin-bottom: 0;
      line-height: 30px;

      @media (max-width: 767px) {
        font-size: 24px;
      }
    }

    h3 {
      font-size: 22px;
      font-weight: 400;
      color: $white;
      margin: 0;
      opacity: 0.6;
      line-height: 30px;
      text-transform: capitalize;

      @media (max-width: 767px) {
        font-size: 18px;
      }
    }

    .owner-image {
      display: inline-block;
      width: 80px;
      height: 80px;
      border-radius: 15px;
      position: absolute;
      left: calc(50% - 40px);
      top: -40px;
      opacity: 1  ;

      .owner-image-inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: inline-block;
      }

      img {
        width: 80px;
        height: 80px;
        border-radius: 15px;
        // border:1px solid rgba(255,255,255,0.2);
      }

      .check-span {
        position: absolute;
        right: 0px;
        bottom: -5px;
        @include gradient();
        width: 25px;
        height: 25px;
        border-radius: 50%;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        color: $white;
        display: none;
      }
    }

    .info-panel {
      text-align: right;
    }

    p {
      font-size: 22px;
      color: $white;
      font-weight: 700;
      margin: 0;

      img {
        display: inline-block;
        position: relative;
        top: -3px;
      }
    }

    span.future-price {
      font-size: 14px;
      font-weight: 400;
      opacity: 0.6;
      color: $white;
    }

    .history-refresh-btn {
      font-size: 14px;
      opacity: 1;
      color: #8a8aa0;
      margin-top: 10px;
      display: inline-block;

      &:hover {
        color: $color;
      }
    }

    .bottom-btn .reg-btn.small {
      font-size: 14px;
      padding: 3px 15px;
    }

    .collection-info {
      display: none;
    }
  }

  &:hover {
    .owner-image,
    .img-pnl {
      opacity: 1;
    }

    .nft-inner {
      box-shadow:  0 0 20px rgba(62, 175, 145, 0.5);
    }
  }

  &.live {
    .btn-cntnr {
      display: flex;
    }

    .bid-time-pnl {
      display: inline-block;
    }

    .post-bid-panel {
      padding-top: 30px;
    }

    .space10,
    // .reg-btn.small{display: none;}
    .owner-image {
      border-radius: 50%;

      img {
        border-radius: 50%;
        border: none;
      }
    }
  }

  &.collection {
    .collection-info {
      display: inline-block;
      width: 100%;
    }

    h2 {
      font-weight: 900;
      font-size: 24px;
    }

    h4 {
      font-size: 16px;
      margin-top: 5px;

      span {
        font-size: 14px;
        opacity: 0.3;
      }
    }

    .owner-image {
      left: 20px;
      border-radius: 30px;

      img {
        border-radius: 30px;
        border: none;
      }

      .check-span {
        display: flex;
      }
    }

    .text-pnl {
      padding: 25px 20px 20px;
    }

    .space10,
    .bottom-btn,
    h6,
    .info-panel,
    .post-bid-panel {
      display: none;
    }
  }
}

/* Top Creater Post */
.author_list {
  column-count: 1;
  grid-column-gap: 20px;
  column-gap: 20px;
  display: inline-block;
  background-color: transparent;
}

/* TOp List Creator Post */
.top-creator-post {
  display: inline-block;
  width: 100%;
  padding: 0 20px;

  @media (max-width: 575px) {
    max-width: 290px;
  }

  .top-creator-post-inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: transparent;
    padding: 0 15px 15px;
    border-radius: 30px;
    position: relative;
    text-align: center;
    cursor: pointer;

    @media (max-width: 767px) {
      padding: 10px;
    }

    &::before {
      position: absolute;
      content: "";
      left: 50%;
      right: 50%;
      bottom: 50%;
      top: 50%;
      border-radius: 30px;
      @include gradient();
      @include trans();

      @media (max-width: 767px) {
        border-radius: 20px;
      }
    }
    &:after{
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      top: 40px;
      border-radius: 30px;
      @include boxshadow();
    }

    .nft-img-pnl {
      width: 80px;
      height: 80px;
      position: relative;
      z-index: 1;

      @media (max-width: 767px) {
        width: 70px;
        height: 70px;
        border-radius: 20px;
      }

      img {
        width: 80px;
        height: 80px;
        border-radius: 30px;

        @media (max-width: 767px) {
          width: 70px;
          height: 70px;
          border-radius: 20px;
        }
      }

      .check-span {
        position: absolute;
        right: 0px;
        bottom: 0px;
        @include gradient();
        width: 25px;
        height: 25px;
        border-radius: 50%;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        color: $white;
        display: flex;
      }
    }

    .txt-pnl {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 1;
      margin-top: 15px;


      .txt-inner {
        display: inline-block;
        width: 100%;
        text-align: left;

        h4 {
          font-size: 20px;
          font-weight: 600;
          line-height: 22px;
          color: $white;
          margin: 0;
          text-transform: capitalize;

          @media (max-width: 767px) {
            font-size: 16px;
          }
        }

        p {
          font-size: 16px;
          font-weight: 400;
          color: $white;
          margin: 0;
          text-align: center;

          @media (max-width: 767px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  &:hover {
    .top-creator-post-inner {
      &::before {
        left: 0%;
        right: 0%;
        bottom: 0%;
        top: 40px;
      }
    }
  }
}

/* TOp List Creator Post */

/* NFT Ends here
================== */

/* Side Filter Bar
================== */
.side-filter-bar {
  display: inline-block;
  border-radius: 15px 15px;
  margin-bottom: 30px;
  width: 300px;
  height: auto;
  padding-top: 25px;
  padding-bottom: 20px;
  background: -moz-linear-gradient(
    -45deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=1);

  @media (max-width: 991px) {
    width: 100%;
    float: none;
  }

  .filter-head-pnl {
    display: flex;
    width: 100%;
    padding: 0 20px;
    justify-content: space-between;
    color: $white;
  }
}

.accordion {
  .card {
    background-color: transparent;
  }

  .card-header {
    padding-bottom: 0;

    button {
      color: $white;
      font-size: 18px;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      background-color: transparent;
      text-decoration: none !important;
    }
  }

  .card-body {
    padding-bottom: 0;

    .form-check {
      margin-bottom: 10px;
    }

    .form-check-label {
      font-size: 16px;
      color: $white;
      cursor: pointer;
    }

    .bar-pnl {
      display: inline-block;
      width: 100%;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      color: $white;

      span {
        color: #8a8aa0;
      }
    }
  }
}

/* Side Filter Bar
================== */

/* Tabs */
.tab-container {
  .side-filter-bar {
    float: left;
  }

  .tab-inner-container {
    display: inline-block;
    width: calc(100% - 300px);
    float: left;
    padding-left: 15px;

    @media (max-width: 991px) {
      width: 100%;
      float: none;
      padding-left: 0;
    }

    h1 {
      font-size: 30px;
      color: $white;
      font-weight: 700;
      margin-bottom: 20px;

      @media (max-width: 767px) {
        font-size: 24px;
      }
    }
  }
}

.sort-list {
  display: inline-block;

  li {
    display: inline-block;
    margin-left: 25px;
    font-size: 16px;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }

    @media (max-width: 575px) {
      width: 100%;
      text-align: right;
      margin-left: 0;
    }

    a {
      font-size: 16px;
      color: $white;

      i {
        margin-left: 5px;
      }

      &:hover {
        color: $purple;
      }
    }
  }
}

/* My Css Starts here
======================= */

/* User NFt Details 
=================== */
.user-nft-head {
  padding-top: 200px;
  position: relative;

  @media (max-width: 1200px) {
    padding-top: 50px;
  }

  &:before {
    position: absolute;
    content: "";
    right: 20%;
    top: 220px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    z-index: -1;

    @include gradient;
    filter: blur(4px);
    @media (max-width: 991px) {
      display: none;
    }
  }

  &:after {
    position: absolute;
    content: "";
    left: 5%;
    top: 180px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $purple;
    z-index: -1;

    @media (max-width: 991px) {
      display: none;
    }
  }
}

.my-profile-Img-pnl {
  display: flex;
  width: 100%;
  position: relative;
  border-radius: 15px;
  min-height: 200px;
  align-items: center;
  justify-content: center;
  @include boxshadow();

  @media (max-width: 991px) {
    width: auto;
    margin-bottom: 20px;
  }

  video,
  img {
    border-radius: 15px;
  }

  .user-fav-heart-span {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 0 0 15px 15px;
    background-image: url(../images/blur-bg.png);
    opacity: 0.9;
    padding: 15px 15px;
    text-align: center;
    background-size: 100% 100%;
    font-size: 20px;
    color: $white;

    img {
      position: relative;
      top: -4px;
      margin-right: 5px;
    }
  }

  &.video-pnl {
    .user-fav-heart-span {
      position: absolute;
      left: unset;
      top: 0px;
      right: 0;
      bottom: unset;
      border-radius: 15px 15px 0 0;
      opacity: 0.9;
      background-image: none;
      padding: 8px 15px;
      text-align: right;
      font-size: 20px;
      color: #000;
    }
  }
}

.user-nft-info-pnl {
  display: inline-block;
  width: 100%;

  h3 {
    font-size: 34px;
    font-weight: 400;
    color: $white;
  }

  p {
    color: rgba(255, 255, 255, 0.3);
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.creator-list {
  display: inline-block;
  width: 100%;

  @media (max-width: 767px) {
    margin-bottom: 0;
  }

  li {
    display: inline-block;
    margin-right: 10px;
    @include boxshadow();
    padding: 15px;
    border-radius: 15px;
    min-width: 250px;
    margin-right: 15px;
    cursor: pointer;
    float: left;

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }

    &:hover {
      background-color: $purple;
    }

    .flex {
      display: flex;
    }

    .img-pnl {
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 10px;
      background-color: #c4c4c4;

      img {
        width: 50px;
        height: 50px;
        border-radius: 10px;
      }
    }

    .txt-pnl {
      display: inline-block;
      padding-left: 15px;

      h6 {
        font-size: 16px;
        color: $white;
        margin: 0;
        text-transform: capitalize;
      }

      span {
        color: #8a8aa0;
        font-size: 14px;
        text-transform: capitalize;
      }
    }
  }
}

.share-info-list {
  display: inline-block;
  padding: 0;
  margin-bottom: 0;

  li {
    display: inline-block;
    margin-right: 10px;
    float: left;
    font-weight: bold;
    text-transform: uppercase;

    // &:first-child{line-height: 30px;}
    p {
      border: none !important;
      color: $white;
      float: left;
      font-size: 16px;
      text-align: center;
      width: 50px;
      position: relative;
      height: 50px;
      padding-left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      margin-bottom: 0;
      cursor: pointer;
      @include gradient();
      @include boxshadow();

      &:hover {
        background-color: $color !important;
        color: $white;
      }

      @media (max-width: 575px) {
        width: 40px;
        height: 40px;
        font-size: 14px;
      }
    }
  }

  a.share-menu-btn {
    position: relative;

    span {
      position: absolute;
      height: 3px;
      border-radius: 25px;
      background-color: #3d3d3d;

      &:nth-of-type(1) {
        left: 40%;
        right: 8px;
        top: 10px;
      }

      &:nth-of-type(2) {
        right: 8px;
        left: 8px;
        top: 17px;
      }

      &:nth-of-type(3) {
        left: 8px;
        right: 40%;
        top: 24px;
      }
    }

    &:hover span {
      background-color: $color;
    }

    &:hover span:nth-of-type(1) {
      left: 8px;
      right: 8px;
    }

    &:hover span:nth-of-type(2) {
      left: 8px;
      right: 8px;
    }

    &:hover span:nth-of-type(3) {
      left: 8px;
      right: 8px;
    }
  }
}

.description-details-text {
  color: $white;
  font-size: 16px;

  b {
    font-size: 30px;
  }
}

.nft-timer {
  display: inline-block;
  position: relative;
  border-radius: 10px;
  padding: 10px;
  background-color: transparent;
  text-align: center;
  border-radius: 10px;
  @include boxshadow();

  &:empty {
    display: none !important;
  }

  .timer {
    display: inline-block;
    width: 100%;
    position: relative;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      position: relative;
      color: $white;
      font-size: 18px;
      font-weight: bold;
      margin: 0 5px;

      &:first-child p {
        background-color: #3EAC91;
      }

      &:nth-of-type(3) p {
        background-color: #3EAC91;
      }

      &:nth-of-type(5) p {
        background-color: #5142fc;
      }

      &:last-child p {
        background-color: #5142fc;
      }

      p {
        font-size: 20px;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        width: 40px;
        height: 50px;
        text-align: center;
        font-weight: bold;
        margin: 0 0 0;
      }

      span {
        font-size: 18px;
        text-transform: capitalize;
        display: inline-block;
        width: 100%;
        font-weight: 600;
        color: #fff;
      }
    }
  }
}

.description-details-pnl {
  display: inline-block;
  width: 100%;
  background-color: rgba(52, 52, 68, 0.32);
  margin-top: 30px;
  border-radius: 15px;
  min-height: 150px;
  padding: 15px;
}

.clrd {
  b {
    color: $white;
  }

  color: $color;
  // white-space: nowrap
}

/* User NFt Details 
=================== */

@media (max-width: 1200px) {
  .wallet-field {
    height: auto;
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .history-btn-cntnr .reg-btn {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .user-nft-head .reg-btn.trans {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .home-nft-conteiner {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  #wallet {
    word-break: break-all;
  }

  .social-container {
    text-align: center;
  }
}

.nft .slick-prev,
.nft .slick-next {
  display: none !important;
}

#root {
  max-width: 100%;
  overflow-x: hidden;
}

.form-control::-webkit-input-placeholder {
  color: grey !important;
}

.form-control:-ms-input-placeholder {
  color: grey !important;
}

.form-control::placeholder {
  color: grey !important;
}

textarea.form-control {
  color: white !important;
}

input.form-control {
  margin-bottom: 20px;
  color: white !important;
}

textarea.black.form-control {
  color: black !important;
}

input.black.form-control {
  color: black !important;
}

.nft-poori-chahy .col-lg-4 {
  .col-lg-6.col-md-6.col-sm-12.col-xl-4 {
    width: 100% !important;
    padding: 0;
    max-width: 100%;
  }
}

select,
select.form-control {
  color: white;
}

select option,
select.form-control option {
  color: black !important;
}

.pic-filed label.form-control {
  background-color: transparent;
  @include gradient();
}

.border-collection {
  border: 1px solid $color;
}

.wordbreak {
  word-break: break-all;
}

#btn_copy {
  border: none;
  @include trans();
}

#btn_copy:disabled {
  color: #fff;
  background: #3EAC91;
  border: 0;
}

.w-adres {
  height: auto;
}

.btn-cntnr {
  .reg-btn {
    width: 172px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
}

.p-rel {
  position: relative;

  .react-datepicker-popper {
    top: -30px !important;
  }
}

.description-details-pnl {
  .clrd {
    white-space: initial;
  }
}

.space-lbl {
  label {
    padding-left: 10px;
  }
}

.nrml-txt p,
.profile_name,
.profile-image-holder .text-pnl h2 {
  word-break: break-all;
}

@media (max-width: 991px) {
  .tab-txt-cntr {
    text-align: center;
  }

  .height {
    height: 20px;
  }
}

@media (max-width: 767px) {
  .tab-inner-container {
    text-align: center;
  }

  .mb-txt-cntr {
    text-align: center;
  }

  .nft__item {
    margin: 0 auto 30px !important;
    max-width: 300px;
  }

  .creator-list li .txt-pnl {
    word-break: break-all;
  }
}

@media (max-width: 575px) {
  .space-lbl {
    .form-check-inline .form-check-input {
      position: absolute;
      left: 0;
      top: 4px;
    }

    label {
      padding-left: 25px;
    }
  }

  .clrd {
    word-break: break-all;
  }

  .slick-prev {
    left: 5px !important;
  }

  .slick-next {
    right: 5px !important;
  }
}

.darker.form-control::-webkit-input-placeholder,
.darker::-webkit-input-placeholder {
  color: grey !important;
}

.darker.form-control:-ms-input-placeholder,
.darker:-ms-input-placeholder {
  color: grey !important;
}

.darker.form-control::placeholder,
.darker::placeholder {
  color: grey !important;
}

.form-control.darker,
input.form-control.darker {
  color: grey !important;
}

.chepi .socail-media-list,
.chupa {
  width: 1270px;
}

@media (max-width: 1300px) {
  .chepi .socail-media-list,
  .chupa {
    width: 1110px;
  }
}

@media (max-width: 1200px) {
  .chepi .socail-media-list,
  .chupa {
    width: 930px;
  }
}

@media (max-width: 991px) {
  .chepi .socail-media-list,
  .chupa {
    width: 690px;
  }
}

@media (max-width: 767px) {
  .chepi .socail-media-list,
  .chupa {
    width: 100%;
  }

  .user-nft-head .reg-btn {
    margin-bottom: 10px;
  }
}

.slick-prev,
.slick-next {
  z-index: 5;
}

.slick-next {
  right: -5px !important;
}

input::file-selector-button {
  background: transparent;
  border: navajowhite;
  color: white;
}

.accordion {
  background: -moz-linear-gradient(
    -45deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=1);

  .accordion-button,
  .accordion-item {
    background-color: transparent;
  }

  .accordion-button {
    color: white;
    font-weight: bold;
    font-size: 18px;
    font-weight: 700;
  }
}

.accordion-button::after {
  // background-image: url();
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

button:focus,
button:focus:not(:focus-visible) {
  box-shadow: none;
}

.choose-select-list {
  // .slick-next{right: -25px !important;}
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.slick-dots {
  display: none !important;
}

.long .slick-next {
  right: -25px !important;
}

@media (max-width: 575px) {
  .long .slick-next {
    right: 5px !important;
  }
}

.short .slick-next {
  right: -5px !important;
}

.slick-slider .slick-slide {
  padding-left: 1px;
}

.rs-slider-progress-bar {
  background-color: #3EAC91;
}

.rs-slider-handle::before {
  border-color: #3EAC91;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #3EAC91;
  background-color: #3EAC91;
}

.form-check-input:checked {
  border-color: #3EAC91;
  background-color: #3EAC91;
}

.accordion-button::after {
  background-image: url(../images/down.png);
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../images/down.png);
}

.import-modal {
  .modal-content {
    background-color: transparent;

    .modal-body {
      border-radius: 15px;
      border: 1px solid rgba(255, 255, 255, 0.14);
      box-shadow: inset 0 -5px 22px rgba(255, 255, 255, 0.15);
      transition: all 0.5s ease-in-out;
      text-align: center;
      padding-top: 60px;
      padding-bottom: 60px;
      position: relative;
      background-color: $black !important;

      h2,
      p {
        color: $white;
      }
    }
  }
}

.make-offer-model-label {
  color: #000000 !important;
  text-align: left !important;
}

.make-offer-blur {
  opacity: 0.6 !important;
}

.creator-list li .img-pnl {
  background-color: transparent;
}

.disabled-button-reg-btn {
  display: inline-block;
  border: none !important;
  padding: 10px 35px;
  text-align: center;
  @include trans();
  font-size: 16px;
  color: $white;
  border-radius: 5px;
  cursor: pointer;

    @include gradient;  background-color: transparent;
  background-image: none;
  @include boxshadow();

  &:hover {
    background-color: $purple;
  }
}

#get-offer-list {
  @media (max-width: 1024px) {
    h6 {
      font-size: 10px;
    }

    .reg-btn {
      width: 20px;
    }
  }
}

.reg-new-btn {
  display: inline-block;
  border: none !important;
  padding: 10px 35px;
  text-align: center;
  @include trans();
  font-size: 16px;
  color: $white;
  border-radius: 5px;
  cursor: pointer;

  @include gradient;
  &:hover {
    color: $white;
    box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.4);
  }

  &:focus {
    border: none !important;
    box-shadow: none !important;
  }

  &.trans {
    background-color: transparent !important;
    background-image: none !important;
    @include boxshadow();

    &:hover {
      background-color: $purple !important;
    }
  }

  &:disabled {
   
    @include gradient;
    cursor: pointer;
    @include boxshadow();
  }

  &.small {
    padding: 6px 15px;
  }

  &.brdr-rad {
    border-radius: 30px;
  }

  &.brdr-rod {
    border-radius: 15px;
  }
}

@media (max-width: 786px) {
  .user-visitor-list {
    .img-pnl {
      position: relative;
      top: 20px;
    }

    .activity-pnl {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (max-width: 575px) {
  .user-visitor-list {
    .left-pnl {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .activity-pnl {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .txt-pnl {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

#traits-body {
  height: 250px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $purple;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #54178d;
  }
}

.jumbotron{
  background-color: transparent;
}

.word {
  position: absolute;
  opacity: 0;
  transform: translateY(100%); /* Start below the container */
  transition: transform 0.8s ease, opacity 0.8s ease;
  white-space: nowrap;
}

.word.visible {
  opacity: 1;
  transform: translateY(0); /* Slide into view */
}

.word.hidden {
  opacity: 0;
  transform: translateY(-100%); /* Slide out above the container */
}