/* Footer Css Starts Here
========================= */
.footer {
  background-color: $darkcolor;
  padding-top: 50px;
  padding-bottom: 50px;
  h5 {
    font-size: 16px;
    color: $white;
  }

  h6 {
    font-size: 20px;
    color: $white;
    padding-top: 20px;
    margin-bottom: 15px;

  }

  p {
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
  }

  .socail-media-list {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 15px;
    gap: 15px;
    li {
      a{
        color: $color;
        img{opacity: 0.7;}
        &:hover{opacity: 1;}
      }
    }
  }

  .footer-list {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;

    li {
      a {
        font-size: 20px;
        color: $white;
        font-weight: 300;

        &:hover {
          color: $color;
        }
      }
    }
  }

  @media (max-width: 767px) {
    li,
    li a,
    p,
    h5,
    h6 {
      opacity: 1;
    }
  }

  li a:hover {
    color: $color;
  }

  @media (max-width: 991px) {
    .child-list li {
      width: 33.3333%;
      float: left;
    }
  }

  @media (max-width: 767px) {
    .three-child-list li,
    .child-list li {
      width: 50%;
      float: left;
    }
  }

  @media (max-width: 575px) {
    text-align: center;

    .socail-media-list li {
      float: none;
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 20px;
    }

    .three-child-list li,
    .child-list li {
      width: 100%;
      float: left;
    }
  }
}

.three-child-list {
  display: inline-block;
  width: 100%;

  li {
    display: inline-block;
    width: 33.333333%;
    float: left;

    a {
      display: inline-block;
    }
  }
}

.subscribe-pnl {
  display: flex;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  justify-content: space-between;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 0;
  margin-bottom: 20px;

  button {
    display: flex;
    width: 45px;
    height: 45px;
    border: none;
    justify-content: center;
    align-items: center;
    @include gradient();
    border-radius: 0 5px 5px 0;
    @include trans();
    color: $white;

    &:hover {
      box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.5);
    }
  }

  input {
    background-color: transparent;
    border-radius: 5px 0 0 5px;
    border: none;
    margin: 0;

    &:focus {
      background-color: transparent;
      border: none;
      box-shadow: none;
      color: white !important;
    }
  }
}
/* Footer Css Ends Here
======================= */